import {ConcTime} from "../ConcTime";
import moment from "moment";
import {DosePlan} from "../DosePlan";
import {CALCULATION, DoseRegime} from "../DoseRegime";
import {Simulation} from "../Simulation";
import {calculateTmic} from "./calculations";
import {Custom} from "../Custom";

const DEBUG = 1

// https://pharmacy.ufl.edu/files/2013/01/5127-28-equations.pdf
export const calculateContinuousConcentration = (rate: number, cl: number, kel: number, t: number) => {
    return (rate / cl) * (1 - Math.exp(-kel * t))
}

// Calculate concentration time series for continuous infusion
// @params halfLife: halfLife of drug as integer
// @params num: number of halfLives to calculate period
// @params label: graph series label
// @params rate: infusion rate (mg/h)
// @params vd: volume of distribution (L)
// @params kel: Elimination coeff (/hr)
// @params cl: Drug clearance (L/hr)
// @params mic: Minimum inhibitory conc (mg/L) of antimicrobial
// @params initialTime : datetime at which initialConcentration is recorded (or estimated), if c0 > 0, includes infusion time
// @params initialConcentration: Initial concentration (mcg/ml)
// @params calculation: Population or Patient
// @returns array of ConcTime objects for plotting
// REF: https://www.boomer.org/c/p4/c06/c0602.php
export const calculateContinuousTimeseries = (halfLife: number, num: number, label: string, rate: number, vd: number, cl: number, kel: number, initialTime: Date, initialConcentration: number, calculation: string): ConcTime[] => {
    const doseArray: ConcTime[] = []
    let concentration: number = initialConcentration

    // initial point
    let time: Date = new Date(initialTime)
    let startTime: number = 1
    // const interval = Math.round(halfLife) * num
    const interval = 24 * num
    const d: ConcTime = new ConcTime({
        interval: label,
        time: new Date(time),
        concentration,
        concentrationUnit: 'mcg/mL',
        calculation
    })
    doseArray.push(d)

    if (DEBUG) {
        console.log(`${label} 0. t=${time} concentration=${concentration} cl=${cl} interval=${interval}`)
    }

    if (concentration > 0) {
        // TODO adjust cl if starting at higher conc and startTime
        // https://www.rxkinetics.com/kin_help/index.html?hlp_theophylline_formulas.htm
        const ss = rate / cl
        const ratio = concentration / ss
        const numHalfLives = ratio >= 0.94 ? 4 : ratio >= 0.88 ? 3 : ratio >= 0.75 ? 2 : ratio >= 0.5 ? 1 : 0

        if (numHalfLives > 0) {
            startTime = halfLife * numHalfLives
            cl = (2 * rate) / (concentration + ss) + ((2 * vd * (concentration - ss)) / ((concentration + ss) * startTime))
            // console.log(`adjust clearance: ${cl} startTime: ${startTime} numHalfLives: ${numHalfLives}`)
            // formula from https://www.rxkinetics.com/kin_help/index.html?hlp_theophylline_formulas.htm
            // CL = [(2 x ko) / (Cp1 + Cp2)] + [(2 x Vd x (Cp1 - Cp2)) / (Cp1 + Cp2) x (t2 -t1)]
            // where ko = administration rate (mg/hr)
            // Cp1 = level (mg/l) drawn 1 hour after start of infusion
            // Cp2 = level (mg/l) 6 hours later
            // Vd = 0.5 l/kg LBW
            // t1 = time Cp1 drawn
            // t2 = time Cp2 drawn
        }
    }

    // infusion C=k0/cl * (1-Math.exp(-kel*t))
    for (let t: number = 1; t < interval; t++) {

        concentration = (concentration * Math.exp(-kel)) + ((rate / (vd * kel)) * (1 - Math.exp(-kel)))
        // concentration = calculateContinuousConcentration(rate, cl, kel, t) // over dt since start


        time = moment(initialTime).add(t, 'hours').toDate()  // add 1 hr
        if (DEBUG >= 1) {
            console.log(`${label} Dose ${t} ${time}. concentration=${concentration}  cl=${cl}`)
        }

        const d = new ConcTime({
            interval: label,
            time: new Date(time),
            concentration,
            concentrationUnit: 'mcg/mL',
            calculation
        })
        doseArray.push(d)
    }
    return doseArray

}
// Generate Dose Plan options for Continuous dosing
// @param sim = Simulation object
// @return sim with simDosePlans and simSelected
export const generateContinuousDosingOptions = (sim: Simulation, custom: Custom | null | undefined): DosePlan[] => {
    const options: DosePlan[] = []
    const weight = sim.simPatient?.weightKg() ?? null
    const rates: number[] = (custom) ? [custom.amount/custom.duration] : sim.simDrug?.getAmountRange('CONT') ?? []
    const rateUnit: string = sim.simDrug?.getAmountUnit('CONT') ?? 'mL/h'
    const mic = sim.getMic()
    if (weight && rates.length > 0) {
        const calculationTypes = sim.getMeasured() && sim.getMeasured().length > 0 ? [CALCULATION.POPULATION, CALCULATION.PATIENT] : [CALCULATION.POPULATION]
        calculationTypes.forEach((calculationType) => {
            if (sim.hasCalculationType(calculationType, 1)) {
                // @ts-ignore
                const givenDoses = sim.simAdministeredDoses.filter((d) => d.calculation === calculationType)
                const lastDose = givenDoses.length > 0 ? givenDoses[givenDoses.length - 1] : null
                const vd = calculationType === CALCULATION.POPULATION ? sim.getPkValue('vd') : sim.getPkValue('vd_p')
                const kel = calculationType === CALCULATION.POPULATION ? sim.getPkValue('kel') : sim.getPkValue('kel_p')
                const cl = calculationType === CALCULATION.POPULATION ? sim.getPkValue('drugcl') : sim.getPkValue('drugcl_p')
                const halfLife = calculationType === CALCULATION.POPULATION ? sim.getPkValue('t12') : sim.getPkValue('t12_p')

                if (vd && kel && cl && halfLife) {
                    // initial concentration
                    const initialConcentration: number = lastDose ? lastDose.concentration * 1 : 0
                    const initialTime: Date = lastDose ? lastDose.time : new Date()
                    const doses = sim.getDoses()
                    const loadingDose = doses.length > 0 ? doses[0].amount * 1 : null
                    const numIntervals = 5
                    const numDays = sim.simDelivery?.daysToDisplay ?? 3
                    const interval = Math.round(halfLife) * numIntervals  //  total period for dose regime, ss in approx 5 half-lives

                    if (DEBUG) {
                        console.log(`${calculationType}: CONT c0=${initialConcentration} mg/L`)
                        console.log(`${calculationType}: CONT t0=${initialTime}`)
                    }

                    // target
                    rates.forEach((rate) => {

                        const recommendation: DoseRegime | null = estimateContinuousDoseRegime(
                            sim,
                            calculationType,
                            initialConcentration,
                            vd, kel, cl,
                            loadingDose,
                            rate, rateUnit,
                            interval,
                            mic)
                        const label: string = `${rate}${rateUnit}`
                        const concTimeSeries: ConcTime[] = calculateContinuousTimeseries(
                            halfLife,
                            numDays,
                            label,
                            rate,
                            vd, cl, kel,
                            initialTime,
                            initialConcentration,
                            calculationType)
                        const highlight = sim.getHighlight(recommendation)
                        options.push(new DosePlan(recommendation, concTimeSeries, highlight))
                        if (DEBUG) {
                            console.log(`Rate: ${label}`)
                            console.log(recommendation)
                            console.log(highlight)
                        }
                    })
                    console.log(`${options.length} option plans for ${calculationType} added`)

                } else {
                    console.error('Pk params missing')
                }
            }
        })
    }
    // Store options
    if (!custom) {
        sim.simDosePlans = options
        // set first in range as default
        sim.simSelected = options.filter((d) => d._rowVariant === 'success')[0] ?? options[0]
    }

    return options
}

export const estimateContinuousDoseRegime =
        (sim: Simulation,
         calculationType: string,
         c0: number,
         vd: number,
         kel: number,
         cl: number,
         loadingDose: number | null,
         rate: number,
         rateUnit: string,
         interval: number | undefined,
         mic: number | undefined): null | DoseRegime => {
    let recommendation: DoseRegime | null = null
    if (sim.isLoaded()) {
        const crcl = sim.getPk('crcl')
        const weight = sim.simPatient?.weightKg()
        if (crcl && weight) {
            const estimateInterval = interval ?? 24;
            const estimateMaintenanceDose = rate * 24 // mg/hr * hr - total dose over 24 hours
            // total dose over 24 hours
            const estimateSS = rate / cl
            const estimateAuc = 0.66 * estimateInterval * estimateSS
            const estimateTmic = calculateTmic(estimateSS, mic, kel, 0)
            recommendation = new DoseRegime({
                calculation: calculationType,
                peak: 'x',
                trough: 'x',
                loading: loadingDose ? loadingDose.toFixed(0) : undefined,
                maintenance: estimateMaintenanceDose ? estimateMaintenanceDose.toFixed(0) : 'x',
                interval: estimateInterval.toFixed(0),
                duration: rate.toFixed(0) ?? '',
                durationUnit: rateUnit ?? '',
                auc: estimateAuc.toFixed(0),
                ss: estimateSS ? estimateSS.toFixed(2) : 'x',
                tmic: estimateTmic ? estimateTmic.toFixed(2) + 'h' : 'x',
                targetMethod: sim.simTarget?.type ?? undefined,
                deliveryMethod: sim.simDelivery?.method ?? undefined
            })
        }
    } else {
        console.error('Error: simulation data is not loaded')
    }
    return recommendation
}