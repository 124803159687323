export class Custom {
    method?: string | undefined;
    amount: number;
    amountUnit: string;
    duration: number;
    durationUnit: string;
    interval: number;
    intervalUnit: string;
    loading: boolean;
    startDt: Date | null | undefined;

    constructor(param: {
        method?: string,
        amount: number, amountUnit: string,
        interval: number, intervalUnit: string,
        duration: number, durationUnit: string,
        loading: boolean,
        startDt?: Date | null | undefined
    }) {
        this.method = param.method;
        this.interval = param.interval;
        this.intervalUnit = param.intervalUnit;
        this.duration = param.duration;
        this.durationUnit = param.durationUnit;
        this.amount = param.amount;
        this.amountUnit = param.amountUnit;
        this.loading = param.loading;
        this.startDt = param.startDt;
    }
}