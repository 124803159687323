export const CONVERT_TO_KG = 0.45359237
export const CONVERT_TO_CM = 2.54
export const CONVERT_TO_INCH = 0.393701
export const CONVERT_TO_MGDL = 0.01131222


let nextPatientId = 13

export class Patient {


    static from(patient: Patient): Patient {
        return new Patient({
            id: patient.id,
            age: patient.age,
            ageUnit: patient.ageUnit,
            height: patient.height,
            heightUnit: patient.heightUnit,
            weight: patient.weight,
            weightUnit: patient.weightUnit,
            gender: patient.gender
        });
    }
    constructor(param: {
        id?: number | null | undefined,
        age: number | undefined,
        ageUnit: string | undefined,
        height: number | undefined,
        heightUnit: string | undefined,
        weight: number | undefined,
        weightUnit: string | undefined,
        gender: string | undefined
    }) {
        if (param) {
            // this.id = param.id ?? Math.ceil(Math.random() * 1000000)
            if (param.id){
                this.id = param.id
            }
            else {
                this.id = nextPatientId++
            }
            this.id = param.id ?? Math.ceil(Math.random() * 1000000)
            this.age = param.age;
            this.ageUnit = param.ageUnit;
            if (typeof param.height === "string") {
                this.height = parseFloat(param.height);
            } else {
                this.height = param.height;
            }
            this.heightUnit = param.heightUnit;
            if (typeof param.weight === "string") {
                this.weight = parseFloat(param.weight);
            } else {
                this.weight = param.weight;
            }
            this.weightUnit = param.weightUnit;
            this.gender = param.gender;
        }
    }

    id?: number | null | undefined;
    age: number | undefined;
    ageUnit: string | undefined;
    height: number | undefined;
    heightUnit: string | undefined;
    weight: number | undefined;
    weightUnit: string | undefined;
    gender: string | undefined;

    // Convert to kg
    // If ideal = true - return Ideal Body weight
    // https://www.mdcalc.com/calc/68/ideal-body-weight-adjusted-body-weight#evidence
    // Ideal Body Weight (Devine formula):
    // Ideal body weight (IBW) (men) = 50 kg + 2.3 kg x (height, in - 60)
    // Ideal body weight (IBW) (women) = 45.5 kg + 2.3 kg x  (height, in - 60)
    // Note: this formula is only an approximation, and is generally only applicable for people 60 inches (5 foot) tall or greater. For patients under 5 feet, one commonly-used modification is to subtract 2-5 lbs for each inch below 60 inches (written communication with leading expert Dr. Manjunath Pai, 2018).
    // Adjusted Body Weight (ABW), for use in obese patients (where actual body weight > IBW):
    // ABW = IBW + 0.4 x (actual body weight - IBW)
    weightKg(ideal = false, adjusted = false): number | null | undefined {
        let bw: number | null | undefined = this.weight;
        if (bw && this.weight && this.weightUnit) {
            const bwkg = this.weightUnit === 'kg' ? this.weight : this.weight * CONVERT_TO_KG
            bw = bwkg
            // calculate IdealBW
            if (ideal && this.height && this.heightUnit && this.gender) {
                const heightInches = this.heightUnit === 'in' ? this.height : this.height * CONVERT_TO_INCH
                if (heightInches > 60) {
                    bw = this.gender === 'Female' ? 45.5 + 2.3 * (heightInches - 60) : 50 + 2.3 * (heightInches - 60)
                    // AdjustedBW
                    if (adjusted && bwkg > bw) {
                        bw = bw + 0.4 * (bwkg - bw)
                    }
                } else {
                    bw = bw - (2 * CONVERT_TO_KG * (60 - heightInches))
                }
            }
            return parseFloat(bw.toFixed(2))
        }
        return bw
    }

    adjustedWeight(): number | null {
        const bw = this.weightKg(false)
        const ideal = this.weightKg(true)
        return (bw && ideal) ? 0.4 * (bw - ideal) + ideal : null
    }

    heightM(): number | null {
        return (this.height && this.heightUnit) ? this.heightUnit === 'cm' ? this.height / 100 : (this.height * CONVERT_TO_CM) / 100 : null
    }

    bmi(): number | null {
        const weight = this.weightKg()
        const height = this.heightM()
        return (weight && height) ? Math.round(weight / (height * height)) : null
    }

    // https://www.omnicalculator.com/health/bsa
    // Du Bois: BSA (m2) = 0.007184 * weight^0.425 * height^0.725
    // weight: kg, height: cm
    // Note not accurate for children
    bsa(): number | null {
        const weight = this.weightKg()
        const height = this.heightM()
        let rtn = null
        if (height && weight) {
            rtn =  0.007184 * Math.pow(height * 100, 0.725) * Math.pow(weight, 0.425)
            rtn = parseFloat(rtn.toFixed(3))
        }
        return rtn
    }

    public isPopulated(): boolean {
        return !(
               this.age == null
            || this.ageUnit == null
            || this.height == null
            || this.heightUnit == null
            || this.weight == null
            || this.weightUnit == null
            || this.gender == null);
    }

}