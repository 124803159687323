export class Delivery {
    method: string;
    // interval: number;
    // intervalUnit: string;
    duration: number;
    durationUnit: string;
    daysToDisplay: number;
    startDT: Date | null | undefined;
    applyInterindividualVariability: boolean | null | undefined = true;

    static from(delivery: Delivery | null): Delivery {
        if (delivery != null) {
            return new Delivery({
                method: delivery.method,
                // interval: delivery.interval,
                // intervalUnit: delivery.intervalUnit,
                duration: delivery.duration,
                durationUnit: delivery.durationUnit,
                daysToDisplay: delivery.daysToDisplay,
                startDT: delivery.startDT,
                applyInterindividualVariability: delivery.applyInterindividualVariability
            });
        }
        return new Delivery({
            method: 'IV',
            // interval: 12,
            // intervalUnit: "hours",
            duration: 1,
            durationUnit: "hours",
            daysToDisplay: 3,
            applyInterindividualVariability: true
        })
    }

    constructor(param: {
        method: string,
        // interval: number,
        // intervalUnit: string,
        duration: number,
        durationUnit: string,
        daysToDisplay: number,
        startDT?: Date | null,
        applyInterindividualVariability?: boolean | null | undefined}) {
        this.method = param.method;
        // this.interval = param.interval;
        // this.intervalUnit = param.intervalUnit;
        this.duration = param.duration;
        this.durationUnit = param.durationUnit;
        this.daysToDisplay = param.daysToDisplay;
        this.startDT = param.startDT;
        this.applyInterindividualVariability = param.applyInterindividualVariability ? param.applyInterindividualVariability : true; // default to true if missing
    }

    isPopulated(): boolean {
        return !(
            this.daysToDisplay == null);
    }
}