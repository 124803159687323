<template>
  <b-card class="my-3 shadow rounded">
    <form>
      <p v-if="error" class="alert alert-danger p-3">{{ error }}</p>
      <v-select
          id="drug"
          v-model="form.drug"
          placeholder="Select Drug ..."
          :options="drugOptions"
          @input="clearSim"
          :disabled="disabled"
          required>
      </v-select>
      <br/>
      <v-select v-if="form.drug"
                id="model"
                v-model="form.model"
                placeholder="Select Model ..."
                :options="modelOptions"
                @input="saveModel"
                :disabled="disabled"
                required>
      </v-select>
    </form>
  </b-card>
</template>

<script>

import {drug_models} from "@/models/modelData/DrugModels.ts";

export default {
  name: "DrugSelect",
  props: ["selectedModel"],
  data() {
    return {
      form: this.initForm(),
      error: null
    }
  },
  watch: {
    selectedModel() {
      this.form = this.initForm()
    }
  },
  computed: {
    drugOptions() {
      return drug_models.map((d) => {
        return {label: d.name, code: d.id}
      })
    },
    modelOptions() {
      return this.selectedDrug ? this.selectedDrug.models.map((m) => {
        return {label: m.name, code: m.code}
      }) : []
    },
    selectedDrug() {
      return drug_models.find((d) => d.id === this.form.drug.code)
    },
    disabled() {
      return this.$store.getters.isReady
    }
  },
  methods: {
    initForm() {
      const selectedDrug = this.$store.getters.getDrug
      const drug = selectedDrug ? {label: selectedDrug.name, code: selectedDrug.id} : null
      const model = this.selectedModel ? {label: this.selectedModel.name, code: this.selectedModel.code} : null
      return {
        ...this.form,
        drug: drug,
        model: model
      }
    },
    saveModel() {
      this.$store.dispatch('clearSimulation')
      this.$store.dispatch('saveDrug', this.selectedDrug)
      this.$store.dispatch('saveModel', this.form.model.code);
    },
    clearSim() {
      this.$store.dispatch('clearSimulation')
      this.$store.dispatch('clearModel')
      this.$store.dispatch('saveDrug', this.selectedDrug)
    }
  }
}
</script>

<style scoped>

</style>
