import {Drug} from "../Drug";
import {Method} from "../Method";
import {Target} from "../Target";
import {DrugModel} from "../DrugModel";
import {ClearanceMethods} from "../utils/constants";
import {Delivery} from "../Delivery";


export const drug_models :Drug[] = [
    new Drug({
        id: 'VANC',
        name: "Vancomycin",
        class: "Glycopeptides",
        dosingUnit: 500,
        maxDosePerHr: 500,
        methods: [new Method(
            {
                type: 'IV',
                intervalRange: [6, 8, 12, 24],
                intervalUnit: 'hours',
                amountRange: [500, 1000, 1500, 2000],
                amountUnit: 'mg',
                infusionRange: [1, 1.5, 2, 2.5, 3, 3.5, 4],
                infusionUnit: 'hours'
            }),
            new Method({
                type: 'CONT',
                intervalRange: [],
                intervalUnit: 'h',
                amountRange: [2000, 2500, 3000, 3500, 4000, 4500, 5000],
                amountUnit: 'mg'
            }),
            // {
            //     //Oral vancomycin is currently available as 125 mg and 250 mg capsules and 250 mg / 5 mL oral solution. It is typically administered four times a day for 7 to 10 days.
            //     //Clearance: 0.71 mL/minute/kg to 1.31 mL/minute/kg in adults with normal renal function
            //     type: 'ORAL',
            //     amountRange: [125, 250],
            //     amountUnit: 'mg',
            //     intervalRange: [6, 8],
            //     intervalUnit: 'h',
            //     bioavailability: 0.1,
            //     halflife: 0.5,
            // }
        ],
        target: [
            new Target({
                type: 'AUC/MIC',
                min: 400,
                max: 600,
                unit: 'mcg*hr/mL',
                range: [100, 700],
                href: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5740375/'
            }),
            new Target({
                type: 'Peak',
                min: 30,
                max: 40,
                unit: 'mg/L',
                range: [10, 60],
                href: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5740375/'
            }),
            new Target({
                type: 'Trough',
                min: 5,
                max: 10,
                unit: 'mg/L',
                range: [1, 30],
                href: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5341531/'
            }),
            new Target({
                type: 'Steady state',
                min: 5,
                max: 10,
                unit: 'mg/L',
                range: [1, 100],
                href: ''
            }),
        ],
        models: [
            new DrugModel({
                code: 'BUEL2005',
                name: 'General: Buelga 2005',
                info: [
                    'General model for Vancomycin',
                    'CL(Vanc)(L/h) = 1.08 × CLCR (Cockcroft-Gault modified) (mL/min)',
                    'Vd (L) = 0.98 × TBW'],
                drug: 'Vancomycin',
                href: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC1315926/',
                delivery: new Delivery({
                    method: 'IV',
                    // interval: 12,
                    // intervalUnit: 'hours',
                    duration: 1,
                    durationUnit: 'hours',
                    daysToDisplay: 3
                }),
                population: 'Adult',
                age: {
                    range: [15, 99],
                    unit: 'years'
                },
                weight: {
                    range: [40, 100],
                    unit: 'kg'
                },
                default: {
                    initialDose: 1000,
                    initialInfusionDuration: 1,
                    loadingDoseMgPerKg: 20, // 20-25mg/kg
                    maintenanceDoseMgPerKg: 15, // 15-20mg/kg
                    minDose: 50,
                    maxDose: 5000,
                    incrementDose: 250,
                    capDose: 2500, // mg
                    capCrcl: 120,
                    kel: (crcl: number) => 0.00083 * crcl + 0.0044, // per hour
                    interval: (crcl: number) => {
                        if (crcl > 100) {
                            return 8
                        } else if (crcl >= 60 && crcl <= 100) {
                            return 12
                        } else {
                            return 24
                        }
                    }
                },
                clearance: [{
                    type: 'one-compartment',
                    coeff: 1.08,
                    sd: 0.2816,
                    unit: 'L/hr',
                    method: ClearanceMethods.SeCR,
                    methodUnit: 'umol/L'
                }],
                vd: [{
                        type: 'one-compartment',
                        coeff: 0.98,
                        sd: 0.3715,
                        unit: 'L/kg'
                    }]
            }),
            new DrugModel({
                code: 'ROBERTS2011',
                name: 'Critically Ill: Roberts 2011',
                info: [
                    'Critically ill ICU patient model for Vancomycin',
                    'BMI < 30 kg/m2',
                    'CL(Vanc)(L/h) = 4.58 * (CrCL (Cockcroft-Gault (mL/min)) / 1.73 (m2) * BSA (m2)) / 100',
                    'Vd (L) = 1.53 × TBW'],
                drug: 'Vancomycin',
                href: 'https://doi.org/10.1128/aac.01708-10',
                delivery: new Delivery({
                    method: 'IV',
                    // interval: 12,
                    // intervalUnit: 'hours',
                    duration: 1,
                    durationUnit: 'hours',
                    daysToDisplay: 3
                }),
                population: 'Adult',
                age: {
                    range: [18, 99],
                    unit: 'years'
                },
                weight: {
                    range: [40, 100],
                    unit: 'kg'
                },
                default: {
                    initialDose: 1000,
                    initialInfusionDuration: 1,
                    loadingDoseMgPerKg: 35,
                    maintenanceDoseMgPerKg: 35,
                    minDose: 500,
                    maxDose: 2500,
                    incrementDose: 250,
                    capDose: 2500, // mg
                    capCrcl: 120,
                    kel: (crcl: number) => 0.00083 * crcl + 0.0044, // per hourrate
                    interval: (crcl: number) => {
                        if (crcl > 100) {
                            return 8
                        } else if (crcl >= 60 && crcl <= 100) {
                            return 12
                        } else {
                            return 24
                        }
                    }
                },
                clearance: [{
                    type: 'one-compartment',
                    coeff: 4.58,
                    sd: 0.389,
                    unit: 'L/hr',
                    method: ClearanceMethods.SeCR,
                    methodUnit: 'umol/L'
                }],
                vd: [{
                    type: 'one-compartment',
                    coeff: 1.53,
                    sd: 0.374,
                    unit: 'L/kg'
                }],
            })
        ]
    }),
    // new Drug({
    //     id: 'PIP',
    //     name: "Piperacillin",
    //     class: "Penicillin class of beta-lactams",
    //     methods: [
    //         new Method({
    //             type: 'IV',
    //             intervalRange: [6, 8, 12, 24],
    //             intervalUnit: 'hours',
    //             amountRange: [2000, 3000, 4000],
    //             amountUnit: 'mg',
    //             infusionRange: [1, 1.5, 2, 2.5],
    //             infusionUnit: 'hours'
    //         })
    //     ],
    //     target: [
    //         new Target({
    //             type: 'fT>MIC',
    //             min: 50,
    //             max: 100,
    //             unit: '%',
    //             range: [1, 100],
    //             href: 'https://pubmed.ncbi.nlm.nih.gov/32383061/'
    //         })
    //     ],
    //     models: [
    //         new DrugModel({
    //             code: 'UDY2015',
    //             name: 'General: Udy 2015',
    //             info: [
    //                 'General model for Piperacillin',
    //                 '[Pip] CL(L/h) = 16.3 × CLCR(urinary, mL/min)/100'],
    //             drug: 'Piperacillin',
    //             href: 'https://pubmed.ncbi.nlm.nih.gov/25632974/',
    //             delivery: new Delivery({
    //                 method: 'IV',
    //                 interval: 6,
    //                 intervalUnit: 'hours',
    //                 duration: 20,
    //                 durationUnit: 'minutes',
    //                 daysToDisplay: 3
    //             }),
    //             population: 'Adult',
    //             age: {
    //                 range: [18, 80],
    //                 unit: 'years'
    //             },
    //             weight: {
    //                 range: [40, 100],
    //                 unit: 'kg'
    //             },
    //             default: {
    //                 minDose: 1000,
    //                 maxDose: 6000,
    //                 kel: (crcl: number) => 0.00083 * crcl + 0.0044, // per hour
    //                 interval: (crcl: number) => {
    //                     if (crcl > 100) {
    //                         return 8
    //                     } else if (crcl >= 60 && crcl <= 100) {
    //                         return 12
    //                     } else {
    //                         return 24
    //                     }
    //                 }
    //             },
    //             clearance: [
    //                 {
    //                     type: 'central',
    //                     coeff: 16.3,
    //                     sd: 0.56,
    //                     unit: 'L/h',
    //                     method: ClearanceMethods.CLCR,
    //                     methodUnit: 'L/h'
    //                 },
    //                 {
    //                     type: 'intercompartmental',
    //                     coeff: 37.3,
    //                     sd: 0,
    //                     unit: 'L/h'
    //                 }
    //             ],
    //             vd: [
    //                 {
    //                     type: 'central',
    //                     coeff: 19.9,
    //                     sd: 0.296,
    //                     unit: 'L'
    //                 },
    //                 {
    //                     type: 'peripheral',
    //                     coeff: 18.8,
    //                     sd: 0.676,
    //                     unit: 'L'
    //                 }
    //             ],
    //             // lagtime: {
    //             //     coeff: 0.8,
    //             //     sd: 0.003,
    //             //     unit: 'h'
    //             // }
    //         })
    //     ]
    // })
]