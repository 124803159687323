<template>
  <b-card>
    <div class="relative w-full">
      <canvas :id="chartId" :width="width" :height="height"></canvas>
    </div>
  </b-card>
</template>

<script>

import {Chart, registerables} from 'chart.js'
import 'chartjs-adapter-moment';
import {DoseTypes} from "@/models/utils/constants";
import {CALCULATION} from "@/models/DoseRegime";
import moment from "moment/moment";
import {CalculationType} from "@/models/Enums";

Chart.register(...registerables)

export default {
  name: "DosePlanGraph",
  props: ['dosePlan', 'showSecondary', 'chartId'],
  data() {
    return {
      width: 300,
      height: 250,
      chart: null,
      chartOptions: {
        responsive: true,
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day'
            },
            position: 'bottom'
          },
          y: {
            title: {
              display: true,
              text: 'mcg/mL'
            }
          }
        },
        plugins: {
          legend: {
            labels: {
              filter: item => item.text !== 'DND'
            }
          }
        }
      },
      chartData: { datasets: [], labels: [] }
    }
  },
  watch: {
    // chartData() {
    //   console.log("Watch chartData...");
    //   this.loadChart()
    // },
    chart() {
      this.loadChart()
    },
    simulation() {
      this.loadChart()
    },
    dosePlan() {
      this.loadChart()
    }
  },
  mounted() {
    this.loadChart()
  },
  methods: {
    loadChart() {
      this.chartData = this.getChartData
      if (this.chart){
        this.chart.data = this.chartData;
        this.chart.update();
      }
      else {
        this.chart = new Chart(this.chartId, {
          type: 'scatter',
          data: this.chartData,
          options: this.chartOptions
        });
      }
    }
  },
  computed: {
    simulation() {
      return this.$store.getters.getSimulation;
    },
    getChartData() {
      let chartDataToReturn = { datasets: [] };
      const sim = this.simulation;
      if (sim.hasMeasured()){
        chartDataToReturn.datasets.push({
              label: 'Measured',
              data: this.getMeasuredConcentrations,
              showLine: false,
              pointRadius: 7,
              pointStyle: 'rectRot',
              borderWidth: 1,
              backgroundColor: 'rgba(168, 50, 133, 0.8)',
              borderColor: 'rgba(168, 30, 103, 0.9)',
            }
        )
      }
      if (this.dosePlan == null) {
          let popAdministered = sim.adminDoseConcForType(CalculationType.POPULATION).map((d) => {return {x: d.time, y: d.concentration};});
          let patAdministered = sim.adminDoseConcForType(CalculationType.PATIENT).map((d) => {return {x: d.time, y: d.concentration};});

          if (patAdministered) {
            chartDataToReturn.datasets.push(
                {
                  label: CalculationType.PATIENT,
                  data: patAdministered,
                  showLine: true,
                  backgroundColor: 'rgba(0, 0, 32, 0.8)',
                  borderColor: 'rgba(0, 0, 0, 0.9)',
                }
            )
            if (this.showSecondary) {
              chartDataToReturn.datasets.push(
                  {
                    label: CalculationType.POPULATION,
                    data: popAdministered,
                    showLine: true,
                    borderWidth: 1,
                    borderDash: [10, 5],
                    backgroundColor: 'rgba(17, 150, 255, 0.5)',
                    borderColor: 'rgba(0, 120, 255, 0.6)',
                  }
              )
            }
          }
          else {
            chartDataToReturn.datasets.push(
                {
                  label: CalculationType.POPULATION,
                  data: popAdministered,
                  showLine: true,
                  backgroundColor: 'rgba(17, 150, 255, 0.8)',
                  borderColor: 'rgba(0, 120, 255, 0.9)',
                }
            )
          }
          // chartDataToReturn.datasets.push({
          //   label: 'Measured',
          //   data: this.getMeasuredConcentrations,
          //   showLine: false,
          //   pointRadius: 2
          // });
          let days = sim._delivery?.daysToDisplay ?? 3
          let endDate = moment(this.simdata && this.simdata.length > 1 ? this.simdata[0].time : Date.now()).add(days, "days").toDate()
          chartDataToReturn.datasets.push(
              {
                label: 'DND',
                data: [{x: endDate, y: 0}],
                showLine: false,
                pointRadius: 0
              },
          );
          return chartDataToReturn;
      }
      this.dosePlan.calculateTimeSeries(sim);
      let interval = 'simulated'
      if (this.dosePlan.administeredTimeSeries != null) {
        chartDataToReturn.datasets.push(
            {
              label: 'Given',
              data: this.dosePlan.administeredTimeSeries.map((d) => {return {x: d.time, y: d.concentration};}),
              showLine: true,

              backgroundColor: this.dosePlan.secondaryAdministeredTimeSeries ? 'rgba(0, 0, 32, 0.8)' : 'rgba(17, 150, 255, 0.8)',
              borderColor: this.dosePlan.secondaryAdministeredTimeSeries ? 'rgba(0, 0, 0, 0.9)' : 'rgba(0, 120, 255, 0.9)',
            }
        )
      }
      if (this.showSecondary && this.dosePlan.secondaryAdministeredTimeSeries) {
        chartDataToReturn.datasets.push(
            {
              label: 'Given(population)',
              data: this.dosePlan.secondaryAdministeredTimeSeries.map((d) => {return {x: d.time, y: d.concentration};}),
              showLine: true,
              borderWidth: 1,
              borderDash: [10, 5],
              backgroundColor: 'rgba(17, 150, 255, 0.8)',
              borderColor: 'rgba(0, 120, 255, 0.9)',
            }
        )
      }
      chartDataToReturn.datasets.push(
          {
            label: interval + (this.dosePlan.secondaryTimeSeries ? '' : '(population)'),
            data: this.dosePlan.mainTimeSeries.map((d) => {return {x: d.time, y: d.concentration};}),
            showLine: true,
            backgroundColor: this.dosePlan.secondaryTimeSeries ? 'rgba(108, 2, 189, 0.8)' : 'rgba(235, 10, 10, 0.8)',
            borderColor: this.dosePlan.secondaryTimeSeries ? 'rgba(128, 2, 169, 0.9)' : 'rgba(255, 0, 0, 0.9)',

          }
      )
      if (this.showSecondary && this.dosePlan.secondaryTimeSeries) {
        chartDataToReturn.datasets.push(
            {
              label: interval + '(population)',
              data: this.dosePlan.secondaryTimeSeries.map((d) => {return {x: d.time, y: d.concentration};}),
              showLine: true,
              borderWidth: 1,
              borderDash: [10, 5],
              backgroundColor: 'rgba(235, 20, 20, 0.5)',
              borderColor: 'rgba(255, 0, 0, 0.6)',
            }
        )
      }

      if (this.dosePlan.doseRegime.deliveryMethod === 'CONT') {
        chartDataToReturn.datasets.push(
            {
              label: 'Steady state',
              data: this.getSteadyStateLevel,
              showLine: true,
              pointRadius: 0,
              borderWidth: 1,
              borderDash: [10, 5],
              backgroundColor: 'rgba(235, 103, 245, 0.7)',
              borderColor: 'rgba(215, 83, 225, 0.8)',

            }
        );
      } else if (this.dosePlan.doseRegime.deliveryMethod === 'ORAL') {
        chartDataToReturn.datasets.push(
            {
              label: 'Peak',
              data: this.getPeakLevel,
              showLine: true,
              pointRadius: 0,
              borderWidth: 1,
              borderDash: [10, 5],
              backgroundColor: 'rgba(245, 128, 2, 0.7)',
              borderColor: 'rgba(255, 100, 0, 0.8)',

            }
        )
      } else {
        chartDataToReturn.datasets.push(
            {
              label: 'Peak',
              data: this.getPeakLevel,
              showLine: true,
              pointRadius: 0,
              borderWidth: 1,
              borderDash: [10, 5],
              backgroundColor: 'rgba(245, 128, 2, 0.7)',
              borderColor: 'rgba(255, 100, 0, 0.8)',
            }
        )
        chartDataToReturn.datasets.push(
            {
              label: 'Trough',
              data: this.getTroughLevel,
              showLine: true,
              pointRadius: 0,
              borderWidth: 1,
              borderDash: [10, 5],
              backgroundColor: 'rgba(250, 180, 105, 0.7)',
              borderColor: 'rgba(230, 160, 85, 0.8)',
            }
        )
      }
      return chartDataToReturn;
    },
    getMeasuredConcentrations() {
      let data = [];
      const sim = this.$store.getters.getSimulation;
      if (sim) {
        data = DoseTypes.CONC in sim._dosesByType ? sim._dosesByType[DoseTypes.CONC].map((d) => {
          return {x: d.datetime, y: d.amount};}) : [];
      }
      return data;
    },
    getPeakLevel() {
      const peak = this.dosePlan.mainResult.peak;
      return this.dosePlan.mainTimeSeries.map((d) => {
        return {x:d.time, y: peak}
      });
    },
    getTroughLevel() {
      const trough = this.dosePlan.mainResult.trough
      return this.dosePlan.mainTimeSeries.map((d) => {
        return {x:d.time, y: trough}
      });
    },
    getSteadyStateLevel() {
      const ss = this.dosePlan.mainResult.ss;
      return this.dosePlan.mainTimeSeries.map((d) => {
        return {x:d.time, y: ss}
      });
    }
  }
}
</script>

<style scoped>

</style>