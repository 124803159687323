<template>
  <div id="display-panels"
       class="my-4 bg-white shadow rounded">
    <b-tabs content-class="mt-3 py-4" v-model="selectedIndex">
      <b-tab v-for="(tab, index) in tabs"
             :title="tab.title"
             :key="tab.title"
      >
        <template v-slot:title>
          {{ tab.title }} <span v-if="index===1 && hasError" class="vertical-middle text-danger"><WarningIcon></WarningIcon></span>
        </template>
        <div class="tab-content">
          <div v-if="tab.index === 0">
            <ModelInfo :model="selectedModel"/>
          </div>
          <div v-else-if="tab.index === 1">
            <DosesTable :model="selectedModel"/>
            <div v-if="showGraph">
              <DosePlanGraph id="PatientDataGraph" :dose-plan="null" :show-secondary="showSecondary" chart-id="PatientData"/>
            </div>
          </div>
          <div v-else-if="tab.index === 2">
            <div class="flex flex-column" v-if="simulation">
              <PlanOptions/>
              <Recommendation/>
              <div v-if="showGraph">
                <DosePlanGraph id="SimulationGraph" :dose-plan="selectedPlan" :show-secondary="showSecondary" chart-id="Simulation"/>
              </div>
              <div v-else>
                <p>Select a dosing plan to see graph.</p>
              </div>
            </div>
            <div v-else>
              <p>To run the precision dosing calculations, select a drug model, and enter patient data.</p>
            </div>
          </div>
          <div v-else-if="tab.index === 3">
            <SavedData :update="update"/>
          </div>

        </div>
      </b-tab>
    </b-tabs>

  </div>
</template>

<script>
import DosesTable from "@/components/display/DosesTable.vue";
import PkParams from "@/components/display/PkParams";
import PlanOptions from "@/components/display/PlanOptions";
import Recommendation from "@/components/display/Recommendation";
import DosePlanGraph from "@/components/display/DosePlanGraph";
import SavedData from "@/components/display/SavedData";
import ModelInfo from "@/components/display/ModelInfo";
import WarningIcon from "vue-material-design-icons/Alert.vue"

export default {
  name: "TabPanels",
  components: {ModelInfo, SavedData, DosePlanGraph, Recommendation, PlanOptions, PkParams, DosesTable, WarningIcon},
  props: {
    update: String,
    activeTab: {
      type: Number,
      default() {
        return 0
      }
    }
  },
  data() {
    return {
      tabs: [],
      selectedIndex: 0
    }
  },
  created() {
    this.tabs = [
      {index: 0, title: 'MODEL INFO'},
      {index: 1, title: 'PATIENT DATA'},
      {index: 2, title: 'SIMULATION RESULTS'},
      {index: 3, title: 'SAVED PATIENTS'}
    ]
  },
  watch: {
    activeTab() {
      this.selectedIndex = this.activeTab
    }
  },
  computed: {
    simulation() {
      return this.$store.getters.getSimulation
    },
    showGraph() {
      return this.selectedPlan || this.simulation?.hasAdministeredDoses;
    },
    showSecondary() {
      return true;
    },
    selectedPlan(){
      return this.$store.getters.getSelectedPlan;
    },
    administeredOnly() {
      return !this.selectedPlan;
    },
    selectedModel() {
      return this.$store.getters.getModel
    },
    isLoading() {
      return this.$store.getters.getLoading
    },
    hasError() {
      return this.$store.getters.getDoses.filter((d) => d.sinceAlert).length > 0
    }
  }
}
</script>

<style scoped>
#display-panels {
  border: 1pt solid #e7e4e4;
  padding-top: 10px;
}

.tab-content {
  min-height: 650px;
}

</style>
