export class Method {
    type: string;
    intervalRange: number[];
    intervalUnit: string;
    infusionRange: number[] | null | undefined;
    infusionUnit: string | null | undefined;
    amountRange: number[];
    amountUnit: string;
    bioavailability?:number | undefined | null;
    halflife?: number | undefined | null;

    constructor(param: {
        type: string,
        intervalRange: number[],
        intervalUnit: string,
        infusionRange?: number[] | null | undefined,
        infusionUnit?: string | null | undefined,
        amountRange: number[],
        amountUnit: string,
        oralBioavailability?: number | undefined | null,
        oralHalflife?: number | undefined | null
    }) {
        this.type = param.type;
        this.intervalRange = param.intervalRange;
        this.intervalUnit = param.intervalUnit;
        this.infusionRange = param.infusionRange;
        this.infusionUnit = param.infusionUnit;
        this.amountRange = param.amountRange;
        this.amountUnit = param.amountUnit;
        this.bioavailability = param.oralBioavailability;
        this.halflife = param.oralHalflife;
    }
}