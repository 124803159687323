import {Delivery} from "./Delivery";
import {Pk} from "./Pk";

export class DrugModel {
    private _code: string;
    private _name: string;
    private _info: string[];
    private _drug: string;
    private _href: string;
    private _delivery: Delivery | null;
    private _population: string;
    private _age: {
        range: number[],
        unit: string;
    };
    private _weight: {
        range: number[],
        unit: string;
    };
    private _clearance: Pk[];
    private _vd: Pk[];
    private _default: any | null | undefined;


    constructor(param: {
        code: string, name: string, info: string[], drug: string, href: string, delivery: Delivery | null,
        population: string, age: { range: number[]; unit: string }, weight: { range: number[]; unit: string },
        clearance: Pk[], vd: Pk[], default: any | null | undefined
    }) {
        this._code = param.code;
        this._name = param.name;
        this._info = param.info;
        this._drug = param.drug;
        this._href = param.href;
        this._delivery = param.delivery;
        this._population = param.population;
        this._age = param.age;
        this._weight = param.weight;
        this._default = param.default;
        this._clearance = param.clearance;
        this._vd = param.vd;
    }


    get code(): string {
        return this._code;
    }

    get name(): string {
        return this._name;
    }

    get info(): string[] {
        return this._info;
    }

    get drug(): string {
        return this._drug;
    }

    get href(): string {
        return this._href;
    }

    get delivery(): Delivery | null {
        return this._delivery;
    }

    get population(): string {
        return this._population;
    }

    get age(): { range: number[]; unit: string } {
        return this._age;
    }

    get weight(): { range: number[]; unit: string } {
        return this._weight;
    }

    get clearance(): Pk[] {
        return this._clearance;
    }

    get vd(): Pk[] {
        return this._vd;
    }

    get default(): any {
        return this._default;
    }

    getClearanceMethod(): string | null | undefined {
        const cl = this._clearance.filter((c) => c.method !== null)[0]
        return cl ? cl.method : null
    }

    getCompartment(): number {
        let rtn = 1 //default to one-compartment
        const cl = this._clearance.filter((c) => c.type !== null)
        if (cl && cl.length > 0) {
            rtn = cl[0].type === 'one-compartment' ? 1 : cl.length
        }
        return rtn
    }

    // Calculate drug clearance by model
    // @params crcl : creatinine clearance (mL/min) or (L/h)
    // @params bsa : body surface area (m2)
    // @return drugCl (L/h)
    drugClearance(crcl: number, bsa: number): number {
        let drugCl = 0
        const clearance = this.clearance[0]
        switch(this.code) {
            case 'BUEL2005':
                // 'CL(Vanc)(L/h) = 1.08 × CLCR (Cockcroft-Gault modified, mL/min)'
                drugCl = crcl * clearance.coeff * (60 / 1000)
                break
            case 'ROBERTS2011':
                // 'CL(Vanc)(L/h) = 4.58 * (CrCL (Cockcroft-Gault, mL/min) /1.73 (m2) * BSA (m2) ) / 100'
                // drugCl = (clearance.coeff * ((crcl / 1.73) * bsa ))/ 100;
                drugCl = (clearance.coeff * ((crcl / bsa ) * 1.73))/ 100;
                break
            case 'UDY2015':
                // '[Pip] CL(L/h) = 16.3 × CLCR(urinary, mL/min)/100'
                drugCl = clearance.coeff * crcl / 100
                break
        }
        return drugCl
    }

    correctSCR(scr: number) {
        if (scr && this.code === 'BUEL2005') {
            return (scr * 1.065) + 0.067;
        }
        return scr;
    }
}