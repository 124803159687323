<template>
  <b-form-group class="accordion" role="tablist">
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" role="tab">
        <div v-b-toggle.doses-panel>DOSES ALREADY GIVEN</div>
      </b-card-header>
      <b-collapse id="doses-panel" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <b-form @submit.prevent="saveDoses">
              <ValidationProvider name="Simulation start" vid="simStartDT" :rules="rules.dose" v-slot="v">
              <p class="text-lg text-black font-semibold">Enter date and time for single dose or starting dose</p>
              <DateTimeInput id="simStartDT" :field="fieldName" @datetimeEvent="initialDoseDateTimeValue"/>
                <b-form-invalid-feedback id="input-start-live-feedback">{{ v.errors[0] }}</b-form-invalid-feedback>
              </ValidationProvider>
              <hr/>
              <p class="text-lg text-black font-semibold">Add a single or loading dose</p>
              <b-input-group>
                <label title="Dose amount given">Dose</label>
                <ValidationProvider name="Dose" vid="initialDose" :rules="rules.dose" v-slot="v">
                  <b-form-input
                      id="initDose"
                      v-model="form.initialDose.amount"
                      type="number"
                      :number="true"
                      class="input-with-unit"
                      placeholder="Amount"
                      :state="getValidationState(v)"
                      aria-describedby="input-1-live-feedback"
                  />
                  <b-form-invalid-feedback id="input-1-live-feedback">{{ v.errors[0] }}</b-form-invalid-feedback>
                </ValidationProvider>
                <b-input-group-append>
                  <ValidationProvider vid="doseUnit">
                    <b-form-select
                        id="initDoseUnit" ref="doseUnit"
                        v-model="form.initialDose.amountUnit"
                        class="unit-dropdown"
                        :options="units.dose"
                        :clearable="false"
                    >
                    </b-form-select>
                  </ValidationProvider>
                </b-input-group-append>
              </b-input-group>
              <b-input-group>
                <label title="Infusion duration">Duration</label>
                <ValidationProvider name="initialDuration" vid="initialDuration" :rules="rules.duration" v-slot="v">
                  <b-form-input
                      id="initDose"
                      v-model="form.initialDose.duration"
                      type="number"
                      :number="true"
                      class="input-with-unit"
                      placeholder="Infusion duration"
                      :state="getValidationState(v)"
                      aria-describedby="input-2-live-feedback"
                  />
                  <b-form-invalid-feedback id="input-2-live-feedback">{{ v.errors[0] }}</b-form-invalid-feedback>
                </ValidationProvider>
                <b-input-group-append>
                  <ValidationProvider vid="durationUnit">
                    <b-form-select
                        id="initDurationUnit" ref="durationUnit"
                        v-model="form.initialDose.durationUnit"
                        class="unit-dropdown"
                        :options="units.duration"
                        :clearable="false"
                    >
                    </b-form-select>
                  </ValidationProvider>
                </b-input-group-append>
              </b-input-group>
              <div v-if="deliveryMethod!=='CONT'">
              <hr/>
              <p class="text-lg text-black font-semibold">Add a sequence of doses</p>
              <b-input-group>
                <label title="Dose amount given">Dose</label>
                <ValidationProvider name="SequentialDose" vid="subDose" :rules="rules.subdose" v-slot="vs">
                  <b-form-input
                      id="subDose"
                      v-model="form.subDose.amount"
                      type="number"
                      :number="true"
                      class="input-with-unit"
                      placeholder="Amount"
                      :state="getValidationState(vs)"
                      aria-describedby="input-3-live-feedback"
                  />
                  <b-form-invalid-feedback id="input-3-live-feedback">{{ vs.errors[0] }}</b-form-invalid-feedback>
                </ValidationProvider>
                <b-input-group-append>
                  <ValidationProvider vid="subdoseUnit">
                    <b-form-select
                        id="subDoseUnit" ref="subdoseUnit"
                        v-model="form.subDose.amountUnit"
                        class="unit-dropdown"
                        :options="units.dose"
                        :clearable="false"
                    >
                    </b-form-select>
                  </ValidationProvider>
                </b-input-group-append>
              </b-input-group>
              <b-input-group>
                <label title="Infusion duration">Duration</label>
                <ValidationProvider name="subDuration" vid="subDuration" :rules="rules.subduration" v-slot="vs">
                  <b-form-input
                      id="subDuration"
                      v-model="form.subDose.duration"
                      type="number"
                      :number="true"
                      class="input-with-unit"
                      placeholder="Infusion duration"
                      :state="getValidationState(vs)"
                      aria-describedby="input-4-live-feedback"
                  />
                  <b-form-invalid-feedback id="input-4-live-feedback">{{ vs.errors[0] }}</b-form-invalid-feedback>
                </ValidationProvider>
                <b-input-group-append>
                  <ValidationProvider vid="subDurationUnit">
                    <b-form-select
                        id="subDurationUnit"
                        v-model="form.subDose.durationUnit"
                        class="unit-dropdown"
                        :options="units.duration"
                        :clearable="false"
                    >
                    </b-form-select>
                  </ValidationProvider>
                </b-input-group-append>
              </b-input-group>
              <b-input-group>
                <label title="Dosing interval">Interval</label>
                <ValidationProvider name="subInterval" vid="subInterval" :rules="rules.interval" v-slot="vs">
                  <b-form-input
                      id="subInterval"
                      v-model="form.subDose.interval"
                      type="number"
                      :number="true"
                      class="input-with-unit"
                      placeholder="Dosing interval"
                      :state="getValidationState(vs)"
                      aria-describedby="input-5-live-feedback"
                  />
                  <b-form-invalid-feedback id="input-5-live-feedback">{{ vs.errors[0] }}</b-form-invalid-feedback>
                </ValidationProvider>
                <b-input-group-append>
                  <ValidationProvider vid="subIntervalUnit">
                    <b-form-select
                        id="subIntervalUnit"
                        v-model="form.subDose.intervalUnit"
                        class="unit-dropdown"
                        :options="units.interval"
                        :clearable="false"
                    >
                    </b-form-select>
                  </ValidationProvider>
                </b-input-group-append>
              </b-input-group>
              <b-input-group>
                <label title="Number of dosing events">Number</label>
                <ValidationProvider name="Number" vid="subNumber" :rules="rules.num" v-slot="vs">
                  <b-form-input
                      id="subNumber"
                      v-model="form.subDose.number"
                      type="number"
                      :number="true"
                      class="input-with-unit"
                      placeholder="Number of doses"
                      :state="getValidationState(vs)"
                      aria-describedby="input-6-live-feedback"
                  />
                  <b-form-invalid-feedback id="input-6-live-feedback">{{ vs.errors[0] }}</b-form-invalid-feedback>
                </ValidationProvider>
                <b-input-group-append><span>&nbsp;</span></b-input-group-append>
              </b-input-group>
              </div>
              <hr/>
              <span class="flex flex-column align-content-center">
              <b-button class="rounded-circle shadow bg-secondary" title="Add doses" @click="saveDoses"
                        :disabled="invalid || !isFormValid"><PlusIcon></PlusIcon></b-button>
            </span>
            </b-form>
          </ValidationObserver>
        </b-card-body>
      </b-collapse>
    </b-card>
  </b-form-group>
</template>

<script>
import {DoseTypes, units} from "@/models/utils/constants.js";
import DateTimeInput from "@/components/inputs/DateTimeInput.vue";
import {createDoseTimeSeries} from "@/components/services/common.js";
import {Dose} from "@/models/Dose";
import PlusIcon from 'vue-material-design-icons/Plus.vue'

export default {
  name: "DoseInput",
  components: {DateTimeInput, PlusIcon},
  props: ["model"],
  data() {
    return {
      form: this.initForm(),
      fieldName: "initial_dose",
      units: units,
      rules: {
        dose: {
          doseBetween: ({
            unit: '@doseUnit',
            model: this.model,
          })
        },
        subdose: {
          doseBetween: ({
            unit: '@subdoseUnit',
            model: this.model,
          })
        },
        duration: {
          durationBetween: ({
            unit: '@durationUnit'
          })
        },
        subduration: {
          durationBetween: ({
            unit: '@subDurationUnit'
          })
        },
        interval: {
          intervalBetween: true
        },
        num: {
          between: [1, 20]
        }

      }
    }
  },
  methods: {
    initForm() {
      return {
        ...this.form,
        initialDose: {
          amount: null,
          amountUnit: 'mg',
          duration: null,
          durationUnit: 'hours',
          datetime: null
        },
        subDose: {
          amount: null,
          amountUnit: 'mg',
          duration: null,
          durationUnit: 'hours',
          interval: null,
          intervalUnit: 'hours',
          number: null
        },
      }
    },
    initialDoseDateTimeValue(event) {
      if (event.field === this.fieldName) {
        this.form.initialDose.datetime = event.value;
      }
    },
    getValidationState(validator) {
      return !validator.pristine && validator.validated ? validator.valid : null;
    },
    saveDoses($event) {
      if (this.isFormValid) {
        this.$refs.observer.validate().then(success => {
          if (success) {
            const dose = new Dose({
              type: DoseTypes.DOSE,
              datetime: new Date(this.form.initialDose.datetime),
              amount: this.form.initialDose.amount,
              amountUnit: this.form.initialDose.amountUnit,
              duration: this.form.initialDose.duration ?? 1,
              durationUnit: this.form.initialDose.durationUnit
            });
            let doses = dose.amount ? [dose] : [];
            if (this.form.subDose.number) {
              const subdose = new Dose({
                type: DoseTypes.DOSE,
                amount: this.form.subDose.amount,
                amountUnit: this.form.subDose.amountUnit,
                duration: this.form.subDose.duration ?? 1,
                durationUnit: this.form.subDose.durationUnit
              });
              doses = createDoseTimeSeries(
                  dose,
                  subdose,
                  this.form.subDose.number,
                  this.form.subDose.interval,
                  this.form.subDose.intervalUnit)
            }
            this.$store.dispatch('addDose', doses);
            this.$emit('switchTab', $event, 1)
            if (this.error) {
              this.$toast.error(this.error)
              this.$store.dispatch('clearError')
            } else {
              this.$toast.success('Doses added')
            }
          }
        });
      }
    }
  },
  computed: {
    deliveryMethod() {
      return this.$store.getters.getDelivery?.method;
    },
    error() {
      return this.$store.getters.getError;
    },
    isFormValid() {
      return this.form.initialDose.datetime !== null && ((this.form.initialDose.duration !== null && this.form.initialDose.amount !== null) ||
          (this.form.subDose.amount !== null && this.form.subDose.duration !== null && this.form.subDose.number !== null && this.form.subDose.interval !== null));
    },
  }
}
</script>

<style scoped>

</style>
