let nextDoseId = 0

export class Dose {

    static from(dose: Dose): Dose {
        if (dose){
            return new Dose({
                duration: dose.duration,
                datetime: dose.datetime,
                amount: dose.amount,
                amountUnit: dose.amountUnit,
                _rowVariant: dose._rowVariant ? dose._rowVariant : "",
                durationUnit: dose.durationUnit,
                type: dose.type,
                since: dose.since,
                sinceAlert: dose.sinceAlert
            });
        }
        return dose;
    }

    constructor(param: {
        duration: number,
        datetime: any,
        amount: number,
        amountUnit: string,
        _rowVariant: string,
        durationUnit: string,
        type: string,
        since?: number | null | undefined,
        sinceAlert?: string | null | undefined
    }) {
        // this.id = Math.ceil(Math.random() * 1000000)
        this.id = nextDoseId++;
        this.datetime = param.datetime;
        this.amount = param.amount;
        this.amountUnit = param.amountUnit;
        this._rowVariant = param._rowVariant;
        this.duration = param.duration;
        this.durationUnit = param.durationUnit;
        this.type = param.type;
        this.since = param.since;
        this.sinceAlert = param.sinceAlert;
    }

    id: number | null | undefined;
    datetime: Date;
    type: string;
    amount: number;
    amountUnit: string;
    duration: number;
    durationUnit: string;
    _rowVariant: string | undefined;
    since: number | null | undefined;
    sinceAlert: string | null | undefined;


    getInfusionDurationInHours(){
        let timeAdjustment = (this.durationUnit === "days") ? 24 : this.durationUnit === "minutes" ? (1/60) : 1;
        return this.duration * timeAdjustment;
    }

    getDosePerHour(){
        return this.amount / this.getInfusionDurationInHours();
    }
}