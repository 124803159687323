import {DeliveryMethod} from "./Enums";

export class DynamicDoseRegime {

    // inputs
    deliveryMethod : DeliveryMethod;
    dose: number;
    loadingDose: number | null;
    infusionDuration: number;
    interval: number;
    startTime: Date;
    // calculated results
    auc: number;
    peak: number;
    trough: number;
    ss: number | null;


    constructor(deliveryMethod: DeliveryMethod, dose: number, loadingDose: number | null, infusionDuration: number, interval: number, startTime: Date, auc: number, peak: number, trough: number, ss: number | null) {
        this.deliveryMethod = deliveryMethod;
        this.dose = dose;
        this.loadingDose = loadingDose;
        this.infusionDuration = infusionDuration;
        this.interval = interval;
        this.startTime = startTime;
        this.auc = auc;
        this.peak = peak;
        this.trough = trough;
        this.ss = ss;
    }
}