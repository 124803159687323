import {CalculationType, TargetType} from "./Enums";


export class SummaryResult {
    calculationType: CalculationType;
    auc: number;
    peak: number;
    trough: number;
    ss: number | null;
    tmic: number | null;
    tmic_percent: number | null;


    constructor(calculationType: CalculationType, auc: number, peak: number, trough: number, ss: number | null, tmic: number | null, tmic_percent: number | null) {
        this.calculationType = calculationType;
        this.auc = auc;
        this.peak = peak;
        this.trough = trough;
        this.ss = ss;
        this.tmic = tmic;
        this.tmic_percent = tmic_percent;
    }

    getTargetValue(type: string ): number | null{
        switch (type) {
            case TargetType.AUC: {
                return this.auc;
            }
            case TargetType.PEAK: {
                return this.peak;
            }
            case TargetType.TROUGH: {
                return this.trough;
            }
            case TargetType.STEADY_STATE: {
                return this.ss;
            }
            case TargetType.FT: {
                return this.tmic;
            }
        }
        return null;
    }
}