import {ConcTime} from "./ConcTime";
import {DynamicDoseRegime} from "./DynamicDoseRegime";
import {SummaryResult} from "./SummaryResult";
import {Target} from "./Target";
import {TargetType} from "./Enums";
import {DynamicSimulation} from "./DynamicSimulation";

let nextDosePlanId = 0;

export class DynamicDosePlan {
    id: number;
    doseRegime: DynamicDoseRegime;
    mainResult: SummaryResult;
    secondaryResult: SummaryResult | null;
    mainTimeSeries: ConcTime[] | null = null;
    secondaryTimeSeries: ConcTime[] | null = null;
    administeredTimeSeries: ConcTime[] | null = null;
    secondaryAdministeredTimeSeries: ConcTime[] | null = null;
    _rowVariant: string | undefined | null;



    constructor(doseRegime: DynamicDoseRegime, mainResult: SummaryResult, secondaryResult: SummaryResult | null) {
        this.id = nextDosePlanId++;
        this.doseRegime = doseRegime;
        this.mainResult = mainResult;
        this.secondaryResult = secondaryResult;
    }


    calculateTimeSeries(simulation: DynamicSimulation) {
        if (this.mainTimeSeries === null) {
            this.mainTimeSeries = simulation.calculateTimeSeries(this.doseRegime, this.mainResult.calculationType);
        }
        if (this.administeredTimeSeries == null){
            this.administeredTimeSeries = simulation.getAdministeredTimeSeries(this.mainResult.calculationType, this.doseRegime.startTime);
        }
        if (this.secondaryTimeSeries === null && this.secondaryResult !== null) {
            this.secondaryTimeSeries = simulation.calculateTimeSeries(this.doseRegime, this.secondaryResult.calculationType);
        }
        if (this.secondaryAdministeredTimeSeries === null && this.secondaryResult !== null) {
            this.secondaryAdministeredTimeSeries = simulation.getAdministeredTimeSeries(this.secondaryResult.calculationType, this.doseRegime.startTime);
        }
    }

    calculateHighlights(target: Target) {
        const type = target.type ? target.type : TargetType.AUC;
        let check = this.mainResult.getTargetValue(type);
        this._rowVariant = check && target.min && target.max && check >= target.min && check <= target.max ? 'success' : ''
    }
}