export class Target {
    type: string | null | undefined;            // target method
    min: number | null | undefined;                    // default min
    max: number | null | undefined;                    // default max
    unit: string | null | undefined;
    range?: Array<number> | null | undefined;   // valid range
    href?: string | null | undefined;


    static from(target: Target | null): Target {
        if (target){
            return new Target( {
                    type: target.type,
                    min: target.min,
                    max: target.max,
                    unit: target.unit,
                    range: target.range,
                    href: target.href
                }
                );
        }
        return new Target({
                type: null,
                min: undefined,
                max: undefined,
                unit: null,
                range: null,
                href: null
            }
        );
    }

    constructor(param: {
        type: string | null | undefined,
        min: number | null | undefined,
        max: number | null | undefined,
        unit: string | null | undefined,
        range?: Array<number> | null | undefined,
        href?: string | null | undefined
    }) {
        if (param) {
            this.type = param.type
            this.min = param.min;
            this.max = param.max;
            this.unit = param.unit;
            this.range = param.range;
            this.href = param.href;
        }
    }

    isPopulated(): boolean {
        return !(
            this.type == null
            || this.min == null
            || this.max == null
            || this.unit == null);
    }
}