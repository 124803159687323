<template>
  <b-card id="interval-tabs"
          class="shadow rounded">
    <b-card-title>Dosing Plan Options</b-card-title>
    <b-card-body>
      <p class="text-muted">Select required plan under Action column from table below. Recommendation and display will update with selection.</p>
      <div class="form-check form-switch float-end">
        <input id="switch"
               class="form-check-input"
               type="checkbox"
               v-model="showAll"/>
        <label for="switch"
               class="form-check-label">Show All</label>
      </div>
      <b-tabs>
        <b-tab v-for="(tab, index) in tabs"
               :title="tab.title"
               :key="tab.title"
               :class='{"tab": true, "tab-bordered": true, "active": (index === selectedIndex)}'
               @click="selectTab(index)">
          <div class="tab-content">
            <div v-if="tab.title==='Custom'">
              <CustomForm :sim="sim"/>
            </div>
            <div v-else>
              <KeepAlive v-if="items.length > 0">
                <SimulationDoses :data="items" :method="tab.title"/>
              </KeepAlive>
              <div class="text-muted font-medium p-4" v-else>
                <p class="text-danger" v-if="sim.hasMeasured">No target plans to display - select Show All</p>
                <p v-else>
                  <span class="text-danger">No plans to display. <br/></span>
                  Add one or more measured concentrations for
                  optimised dose plans. Best results are with 2 or more concentrations (peak and trough) in one dose
                  interval.
                </p>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-card-body>
  </b-card>
</template>

<script>
import SimulationDoses from "@/components/display/SimulationDoses";
import CustomForm from "@/components/inputs/CustomForm.vue";
import {DeliveryMethod} from "@/models/Enums";
import {DynamicDosePlan} from "@/models/DynamicDosePlan";

export default {
  name: "PlanOptions",
  components: {CustomForm, SimulationDoses},
  data() {
    return {
      selectedIndex: 0,
      tabs: [],
      showAll: false,
    }
  },
  watch: {
    drug() {
      this.loadTabs();
    }
  },
  computed: {
    items() {
      return this.showAll ? this.loadOptions() : this.loadOptions().filter((option) => option._rowVariant)
    },
    sim() {
      return this.$store.getters.getSimulation
    },
    drug() {
      return this.$store.getters.getDrug
    },
    // tabs() {
    //   let tabs = Object.keys(DeliveryMethod).map((d, i) => {
    //     return {index: i, title: DeliveryMethod[d]}
    //   })
    //   const drug = this.$store.getters.getDrug
    //   if (drug){
    //     const drugMethods = drug.methods.map((method) => {method.type});
    //     tabs = tabs.filter((d, i) => {
    //       drugMethods.some((method) => {method === i})
    //     })
    //   }
    //   tabs.push({index: this.tabs.length, title: 'Custom'})
    //   return tabs;
    // }
  },
  created() {
    this.loadTabs()
  },
  methods: {
    nformat(value, decimals) {
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals);
    },
    loadTabs() {
      if (this.drug){
        this.tabs = Object.keys(DeliveryMethod).map((d, i) => {
          return {index: i, title: DeliveryMethod[d]}
        })
        this.tabs = this.tabs.filter((tab) => {
          return this.drug.methods.some((method) => {
            const result = method.type == tab.title;
            return result;
          }
          )
        })
      }
      else {
        this.tabs = [];
      }
      this.tabs.push({index: this.tabs.length, title: 'Custom'})
    },
    loadOptions() {
      if (this.tabs.length > 0 && this.sim) {
        this.selectedTab = this.tabs.filter((tab) => tab.index === this.selectedIndex)[0]
        const selectedData = this.sim.plansForDeliveryMethod(this.selectedTab.title);
        return selectedData.map((s) => {

          if (s.doseRegime.deliveryMethod === 'CONT') {
            return {
              // Interval: s.doseRegime.interval,
              // intervalUnit: 'h',
              Dose: s.doseRegime.dose,
              doseUnit: 'mg',
              // Rate: s.doseRegime.duration,
              // rateUnit: s.recommendation.durationUnit,
              AUC: this.nformat(s.mainResult.auc, 2),
              aucUnit: "mcg*hr/mL",
              SS: this.nformat(s.mainResult.ss, 2),
              ssUnit: 'mcg/mL',
              plan: s,
              _rowVariant: s._rowVariant
            }
          } else if (this.sim.model.getCompartment() === 2) {
            return {
              Interval: s.doseRegime.interval,
              intervalUnit: 'h',
              Dose: s.doseRegime.dose,
              doseUnit: 'mg',
              Infusion: s.doseRegime.infusionDuration,
              InfusionUnit: 'h',
              TMIC: this.nformat(s.mainResult.tmic, 2),
              Peak: this.nformat(s.mainResult.peak, 2),
              peakUnit: 'mcg/mL',
              plan: s,
              _rowVariant: s._rowVariant
            }
          } else {
            return {
              Interval: s.doseRegime.interval,
              intervalUnit: 'h',
              Dose: s.doseRegime.dose,
              doseUnit: 'mg',
              Infusion: s.doseRegime.infusionDuration,
              InfusionUnit: 'h',
              AUC: this.nformat(s.mainResult.auc, 2),
              aucUnit: "mcg*hr/mL",
              Peak: this.nformat(s.mainResult.peak, 2),
              peakUnit: 'mcg/mL',
              Trough: this.nformat(s.mainResult.trough, 2),
              troughUnit: 'mcg/mL',
              plan: s,
              _rowVariant: s._rowVariant
            }
          }
        })
      }
    },
    selectTab(idx) {
      this.$store.dispatch('clearSelectedPlan')
      this.selectedIndex = idx;
      this.tabs.forEach((tab, index) => {
        tab.isActive = index === this.selectedIndex;
      })
      this.loadOptions()
    }
  }
}
</script>

<style scoped>

</style>