<template>
  <div id="recommendation-panel">
    <b-card id="recommendation"
            class="shadow rounded">
      <b-card-title>Selected dose simulation regimen</b-card-title>
      <b-card-body>
        <p><label class="text-muted font-italic"><i>Simulation may include a loading dose, if provided by the model.</i></label></p>
        <span class="title text-success">{{ recommendation }}  </span>
        <button class='right border-0' title="Copy to clipboard" @click='copyRecommendation'>
          <BIconClipboard/>
        </button>
      </b-card-body>
    </b-card>
    <b-card
        class="shadow rounded">
      <b-card-title v-b-toggle.details-panel>PK/PD parameters
        <b-icon-chevron-double-right/>
      </b-card-title>
      <b-collapse id="details-panel" class="mt-2">
        <b-card-body>
          <b-row>
            <b-col>
              <div class="title">PK params</div>
              <PkParams/>
            </b-col>
            <b-col>
              <div class="title">Simulation</div>
              <b-table
                  striped
                  fixed
                  small
                  responsive="sm"
                  :items="items"
                  :fields="fields">
                <template #cell(unit)="{item}"><span v-html="displaySIUnits(item.unit)"></span></template>
              </b-table>
            </b-col>
          </b-row>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import PkParams from "@/components/display/PkParams";
import {displaySIUnits} from "@/models/utils/constants";
import {BIconClipboard} from "bootstrap-vue";

export default {
  name: "Recommendation",
  components: {PkParams, BIconClipboard},
  methods: {displaySIUnits,
    nformat(value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals);
    },
    async copyRecommendation() {
      try {
        await navigator.clipboard.writeText(this.recommendation)
        this.$toast.success('Copied: ' + this.recommendation)
      } catch ($e) {
        this.$toast.error('Cannot copy citation')
      }
    }},
  data() {
    return {
      fields: [
        {
          key: 'label',
          sortable: true
        },
        {
          key: 'value',
          sortable: true
        },
        {
          key: 'unit',
          sortable: true
        }
      ]
    }
  },
  computed: {
    recommendation() {
      const selectedPlan = this.$store.getters.getSelectedPlan
      if (selectedPlan) {
        const drug = this.$store.getters.getDrug
        const drugName = drug?.name ?? 'drug'
        const loading = selectedPlan.doseRegime.loadingDose
        const loadingDuration = (drug?.maxDosePerHr) ? loading / drug.maxDosePerHr :
            (selectedPlan.doseRegime.deliveryMethod === 'CONT') ? 6 : selectedPlan.doseRegime.infusionDuration;
        const load = loading && loading !== selectedPlan.doseRegime.dose ? `${loading} mg loading over ${loadingDuration} hours then ` : ''
        const dose = selectedPlan.doseRegime.dose ?? 'x'
        const interval = selectedPlan.doseRegime.interval ?? 'x'
        const infusion_duration = selectedPlan.doseRegime.infusionDuration ?? 'x'
        const infusion_durationUnit ='hours'
        const delivery = selectedPlan.doseRegime.deliveryMethod
        return delivery === 'CONT' ?
            `${drugName} ${load} ${dose} mg over 24 hours continuous IV infusion` :
            delivery === 'ORAL' ?
                `${drugName} ${load}${dose} mg ${delivery} every ${interval} hours` :
                `${drugName} ${load}${dose} mg ${delivery} every ${interval} hours with ${infusion_duration} ${infusion_durationUnit} infusion`
      }
      return ''
    },
    items() {
      const selectedPlan = this.$store.getters.getSelectedPlan
      if (selectedPlan) {
        return [
          {
            label: "AUC/MIC",
            value: selectedPlan.mainResult.auc ? this.nformat(selectedPlan.mainResult.auc, 2) : 'x',
            unit: selectedPlan.mainResult.auc ? "mcg*hr/mL" : ''
          },
          {
            label: "Peak",
            value: selectedPlan.mainResult.peak ? this.nformat(selectedPlan.mainResult.peak,2) : 'x',
            unit: selectedPlan.mainResult.peak ? "mcg/mL" : ''
          },
          {
            label: "Trough",
            value: selectedPlan.mainResult.trough ? this.nformat(selectedPlan.mainResult.trough, 2) : 'x',
            unit: selectedPlan.mainResult.trough ? "mcg/mL" : ''
          },
          {
            label: "Loading",
            value: selectedPlan.doseRegime.loading ?? 'x',
            unit: selectedPlan.doseRegime.loading ? "mg" : ''
          },
          {
            label: "Maintenance",
            value: selectedPlan.doseRegime.dose ?? 'x',
            unit: selectedPlan.doseRegime.dose ? "mg" : ''
          },
          {
            label: "Interval",
            value: selectedPlan.doseRegime.interval ?? 'x',
            unit: "hours"
          },
          {
            label: "Infusion duration",
            value: selectedPlan.doseRegime.infusionDuration ?? 'x',
            unit:'hours'
          },
          {
            label: "Steady state",
            value: selectedPlan.mainResult.ss ? this.nformat(selectedPlan.mainResult.ss,2) : 'x',
            unit: selectedPlan.mainResult.ss ? "mcg/mL" : ''
          },
          {
            label: "fT > MIC",
            value: selectedPlan.mainResult.tmic ? this.nformat(selectedPlan.mainResult.tmic, 2) : 'x',
            unit: selectedPlan.mainResult.tmic ? "%" : ''
          }
        ]
      }
      return []
    }
  }
}
</script>

<style scoped>
#recommendation-panel {
  margin-top: -2em;
}

.title {
  font-weight: bold;
}

</style>
