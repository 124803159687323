import {Patient, CONVERT_TO_MGDL} from "./Patient";
import {Dose} from "./Dose";
import {ConcTime} from "./ConcTime";
import moment from "moment";
import {DoseTypes, PkType} from "./utils/constants";
import {Target} from "./Target";
import {Pathogen} from "./Pathogen";
import {PkParam, PkParamModel} from "./PkParam";
import {DrugModel} from "./DrugModel";
import {Delivery} from "./Delivery";
import {Drug} from "./Drug";
import {AmwgSampler} from "../plugins/mcmc.js";
import * as ss from 'simple-statistics';
import {Pk} from "./Pk";
import {CalculationType, DeliveryMethod} from "./Enums";
import {DynamicDoseRegime} from "./DynamicDoseRegime";
import {DynamicDosePlan} from "./DynamicDosePlan";
import {SummaryResult} from "./SummaryResult";
import {drug_models} from "./modelData/DrugModels";
import {Method} from "./Method";
import {Custom} from "./Custom";

const DEBUG = 1


function addDate(start: Date, amount: number, unit: string){
    if (unit === "days"){
        return moment(start).add(amount, "days").toDate();
    }
    else if (unit === "minutes"){
        return moment(start).add(amount, "minutes").toDate();
    }
    return moment(start).add(amount, "hours").toDate();
}

//* from distributions.js */
function norm(x: number, mean: number, sd: number) {
    return -0.5 * Math.log(2 * Math.PI) - Math.log(sd) - Math.pow(x - mean, 2) / (2 * sd * sd);
}

function unif(x: number, min: number, max: number) {
    return (x < min || x > max) ? -Infinity : Math.log(1 / (max - min));
}

// Beta param for two component
// @params Q : intercompartmental clearance (L/h)
// @params vd1: Vd central (L)
// @params vd2: Vd peripheral (L)
// @params cl: drug clearance (L/h)
// http://jupiter.chem.uoa.gr/thanost/papers/papers8/PFIM_PKPD_library.pdf
function calculateBeta(Q: number, vd1: number, vd2: number, cl: number): number {
    const cpt1 = Q / vd1
    const cpt2 = Q / vd2
    const K = cl / vd1
    return 0.5 * (cpt1 + cpt2 + K - Math.sqrt(((cpt1 + cpt2 + K) * (cpt1 + cpt2 + K)) - 4 * (cpt2 * K)))
}

// Alpha param for two component
// @params Q : intercompartmental clearance (L/h)
// @params vd1: Vd central (L)
// @params vd2: Vd peripheral (L)
// @params cl: drug clearance (L/h)
// @params beta: beta slope
// http://jupiter.chem.uoa.gr/thanost/papers/papers8/PFIM_PKPD_library.pdf
function calculateAlpha(Q: number, vd1: number, vd2: number, cl: number, beta: number): number{
    const cpt2 = Q / vd2
    const K = cl / vd1
    return (cpt2 * K) / beta
}


function calculateACompartment(Q: number, vd1: number, vd2: number, alpha: number, beta: number): number {
    const cpt2 = Q / vd2;
    return (1 / vd1) * ((alpha - cpt2) / (alpha - beta));
}

function calculateBCompartment(Q: number, vd1: number, vd2: number, alpha: number, beta: number): number {
    const cpt2 = Q / vd2
    return (1 / vd1) * ((beta - cpt2) / (beta - alpha));
}

function convertToHours(amount: number, unit: string): number {
    let timeAdjustment = (unit === "days") ? 24 : unit === "minutes" ? (1/60) : 1;
    return amount * timeAdjustment;
}


class AdministeredDose {
    dose: Dose;
    infusionEndDt: Date;
    infusionHrs: number;
    startConcentration: number;
    infusionEndConcentration: number;
    startHr: number;

    constructor(dose: Dose, startConcentration: number, infusionEndConcentration: number, infusionEndDt: Date,
                infusionHrs: number, startHr: number) {
        this.dose = dose;
        this.infusionEndDt = infusionEndDt;
        this.infusionHrs = infusionHrs;
        this.startConcentration = startConcentration;
        this.infusionEndConcentration = infusionEndConcentration;
        this.startHr = startHr;
    }
    getDosePerHour(){
        return this.dose.amount / this.infusionHrs;
    }
}


abstract class ConcentrationCalculator {

    protected constructor() {
    }

    concentrationDuringInfusion(startConcentration: number, dosePerHr: number, infusionInHrs: number): number{
        console.error("Abstract version should not be called");
        return 0;
    }

    concentrationDuringDecay(startConcentration: number, decayInHrs: number): number{
        console.error("Abstract version should not be called");
        return 0;
    }

    calculatePeak(intervalInHrs: number, infusionInHrs:number , dosePerHr: number): number{
        console.error("Abstract version should not be called");
        return 0;
    }

    calculateTroughFromPeak(peak: number, intervalInHrs: number, infusionInHrs:number): number{
        return this.concentrationDuringDecay(peak, intervalInHrs - infusionInHrs);
    }

    calculateTrough(intervalInHrs: number, infusionInHrs:number , dosePerHr: number): number{
        return this.calculateTroughFromPeak(this.calculatePeak(intervalInHrs, infusionInHrs, dosePerHr), intervalInHrs, infusionInHrs);
    }


    calculateTmic(intervalInHrs: number, infusionInHrs:number, dosePerHr: number, mic: number | null | undefined): number | null{
        return this.calculatTmicFromPeak(this.calculatePeak(intervalInHrs, infusionInHrs, dosePerHr), intervalInHrs, infusionInHrs, mic);
    }

    calculatTmicFromPeak(peak: number, intervalInHrs: number, infusionInHrs:number, mic: number | null | undefined): number | null{
        console.error("Abstract version should not be called");
        return 0;
    }

    calculateAucFromPeakAndTrough(peak: number, trough: number, intervalInHrs: number, infusionInHrs:number, mic: number | null | undefined): number{
        const linear_area = infusionInHrs * ((trough + peak) / 2);
        const decay_area = (peak === trough) ? 0 : ((peak - trough) / (Math.log(peak) - Math.log(trough))) * (intervalInHrs - infusionInHrs);
        const auc_0_tau = linear_area + decay_area;
        const auc_0_24 = (auc_0_tau * 24) / intervalInHrs;
        return mic ? auc_0_24 / mic : auc_0_24;
    }

    calculateAuc(intervalInHrs: number, infusionInHrs:number, dosePerHr: number, mic: number): number{
        const peak = this.calculatePeak(intervalInHrs, infusionInHrs, dosePerHr);
        const trough = this.calculateTroughFromPeak(peak, intervalInHrs, infusionInHrs);
        return this.calculateAucFromPeakAndTrough(peak, trough, intervalInHrs, infusionInHrs, mic);
    }

    calculateSteadyState(dose: number, intervalInHrs: number, infusionInHrs: number): number{
        console.error("Abstract version should not be called");
        return 0;
    }

}


class OneCompartmentConcentrationCalculator extends ConcentrationCalculator {
    private readonly kel: number;
    private readonly vd: number;

    constructor(kel: number, vd: number) {
        super();
        this.kel = kel;
        this.vd = vd;
    }

    concentrationDuringInfusion(startConcentration: number, dosePerHr: number, infusionInHrs: number): number{
        return (startConcentration * Math.exp(-this.kel * infusionInHrs )) + (((dosePerHr) / (this.vd * this.kel)) * (1 - Math.exp(-this.kel * infusionInHrs)));
    }

    concentrationDuringDecay(startConcentration: number, decayInHrs: number): number{
        return  startConcentration * Math.exp(-this.kel * decayInHrs);
    }

    calculatePeak(intervalInHrs: number, infusionInHrs:number , dosePerHr: number): number{
        return (dosePerHr / (this.vd * this.kel)) * (1 - Math.exp(-this.kel * infusionInHrs) ) / (1 - Math.exp(-this.kel * intervalInHrs));
    }

    calculatTmicFromPeak(peak: number, intervalInHrs: number, infusionInHrs:number, mic: number | null | undefined): number | null{
        if (mic){
            let t_inf_mic = 0
            if (infusionInHrs > 0) {
                const c = Math.sqrt(infusionInHrs * infusionInHrs + peak * peak);
                const sinA = (infusionInHrs * Math.sin(90)) / c;
                const tmic = (sinA * sinA * mic) / (infusionInHrs * peak);
                t_inf_mic = infusionInHrs - tmic;
            }
            // Decay from Peak to MIC + infusion from MIC
            return (Math.log(peak / mic) / this.kel) + t_inf_mic;
        }
        return null;
    }

    calculateSteadyState(dose: number, intervalInHrs: number, infusionInHrs: number): number{
        const cl: number = this.kel * this.vd;
        return dose / (cl * intervalInHrs)
    }

}

class TwoCompartmentConcentrationCalculator extends ConcentrationCalculator {
    private readonly alpha: number;
    private readonly beta: number;
    private readonly A: number;
    private readonly B: number;

    constructor(alpha: number, beta: number, A: number, B: number) {
        super();
        this.alpha = alpha;
        this.beta = beta;
        this.A = A;
        this.B = B;
    }

    concentrationDuringInfusion(startConcentration: number, dosePerHr: number, infusionInHrs: number){
        const Aphase = (this.A / this.alpha) * (1 - Math.exp(-this.alpha)) * Math.exp(-this.alpha * infusionInHrs);
        const Bphase = (this.B / this.beta) * (1 - Math.exp(-this.beta)) * Math.exp(-this.beta * infusionInHrs);
        return  (startConcentration + (infusionInHrs * dosePerHr)) * (Aphase + Bphase);
    }

    concentrationDuringDecay(startConcentration: number, decayInHrs: number){
        const Aphase = (this.A / this.alpha) * (1 - Math.exp(-this.alpha)) * Math.exp(-this.alpha * decayInHrs);
        const Bphase = (this.B / this.beta) * (1 - Math.exp(-this.beta)) * Math.exp(-this.beta * decayInHrs);
        return startConcentration * (Aphase + Bphase);
    }

    calculatePeak(intervalInHrs: number, infusionInHrs:number , dosePerHr: number): number{
        // We don't have the actual formula for this, lets just iterate until it doesn't change by some epsilon amount
        const episilon = 0.00001;
        let concentration = 0
        let lastConcentration = -100
        const decayInHrs = intervalInHrs - infusionInHrs;
        let iterationCount = 0;
        while (iterationCount < 200 && Math.abs(concentration - lastConcentration) > episilon){
            lastConcentration = concentration;
            concentration = this.concentrationDuringInfusion(lastConcentration, dosePerHr, infusionInHrs);
            concentration = this.concentrationDuringDecay(concentration, decayInHrs);
            iterationCount++;
        }
        return concentration;
    }

    calculatTmicFromPeak(peak: number, intervalInHrs: number, infusionInHrs:number, mic: number | null | undefined): number | null{
        if (mic){
            let t_inf_mic = 0
            if (infusionInHrs > 0) {
                const c = Math.sqrt(infusionInHrs * infusionInHrs + peak * peak);
                const sinA = (infusionInHrs * Math.sin(90)) / c;
                const tmic = (sinA * sinA * mic) / (infusionInHrs * peak);
                t_inf_mic = infusionInHrs - tmic;
            }
            // Don't know haw to aply the Decay from Peak to MIC + infusion from MIC
            const decay = 1; // @TODO fix me
            return (Math.log(peak / mic) / decay) + t_inf_mic;
        }
        return null;
    }

    calculateSteadyState(dose: number, intervalInHrs: number, infusionInHrs: number): number{
        // @TODO this is probably wrong
        const t = intervalInHrs - infusionInHrs
        const cA = this.A / this.alpha * (((1 - Math.exp(-this.alpha * infusionInHrs)) * Math.exp(-this.alpha * t)) / (1 - Math.exp(-this.alpha * intervalInHrs)))
        const cB = this.B / this.beta * (((1 - Math.exp(-this.beta * infusionInHrs)) * Math.exp(-this.beta * t)) / (1 - Math.exp(-this.beta * intervalInHrs)))
        return (dose / infusionInHrs) * (cA + cB)
    }
}

/**
 * This is a rewrite of the Simulation class which is designed to update as each change it made
 * rather than being triggered by calls to generate.
 */
export class DynamicSimulation {
    // main inputs
    private readonly _patient: Patient | null = null;
    private readonly _model: DrugModel | null = null;
    private readonly _pathology: Pathogen | null = null;
    private readonly _doses: Dose[] = [];
    private readonly _target: Target | null = null;
    private readonly _drug: Drug | null = null;
    private readonly _delivery: Delivery | null = null;
    private _selectedPlan: DynamicDosePlan | null = null;

    // derived data
    private _dosesByType: Record<string, Dose[]> = {};
    private _sawchukZaskePair: null | {dose: Dose, doseIdx: number, firstMeasure: Dose, lastMeasure: Dose, interval: number} = null;
    private _pkParamModel: PkParamModel = new PkParamModel();
    private _administeredDoses: Record<string, AdministeredDose[]> = {}
    private _administeredDoseConc: Record<string, ConcTime[]> = {}
    private _plansByDeliveryMethod: Record<string, DynamicDosePlan[]> = {};


    constructor(param: {
            patient: Patient | null
            model?: DrugModel | null,
            modelId?: string | null
            pathology: Pathogen | null,
            doses: Dose[],
            target: Target | null | {target: string, pdtMax: number|null, pdtMin: number|null, pdtUnit: string}
            drug?: Drug | null,
            drugId?: string | null,
            delivery: Delivery | null}) {
        if (param.patient){
            this._patient = Patient.from(param.patient);
        }
        this._patient = param.patient;
        if (param.drug){
            this._drug = param.drug;
        }
        else if (param.drugId){
            for (const d of drug_models){
                if (d.id === param.drugId){
                    this._drug = d;
                }
            }
        }

        let haveModel = false;
        if (param.model){
            haveModel = true;
            this._model = param.model;
        }
        else if (param.modelId){
            if (this._drug){
                for (const m of this._drug.models){
                    if (m.code === param.modelId){
                        this._model = m;
                        haveModel = true;
                    }
                }
            }
        }
        if (!haveModel){
            for (const d of drug_models){
                for (const m of d.models){
                    if (m.code === param.modelId){
                        this._model = m;
                        this._drug = d;
                    }
                }
            }
        }

        this._pathology = param.pathology;
        // copy doses in time order
        this._doses = param.doses.map(d=> Dose.from(d)).sort((a, b) => a.datetime.getTime() - b.datetime.getTime());
        this._target = param.target == null || param.target instanceof Target ? Target.from(param.target) :
                new Target({type: param.target.target, min: param.target.pdtMin, max: param.target.pdtMax, unit: param.target.pdtUnit});
        this._delivery = Delivery.from(param.delivery);

        this.generateDosesByType();
        if (this.canGeneratePlans()){
            this.generateSawchukZaskePair();
            this.generatePkParamModel();
            this.generateAdministeredDoseConcentrations();
            this.generateDosePlans()
        }
    }

    public get patient(): Patient | null {
        return this._patient;
    }

    public get model(): DrugModel | null {
        return this._model;
    }

    public get pathology(): Pathogen | null {
        return this._pathology;
    }

    public get doses(): Dose[] {
        return this._doses;
    }

    public get target(): Target | null {
        return this._target;
    }

    public get drug(): Drug | null {
        return this._drug;
    }

    public get delivery(): Delivery | null {
        return this._delivery;
    }

    public get dosesByType(): Record<string, Dose[]> {
        return this._dosesByType;
    }

    public get sawchukZaskePair(): { dose: Dose; doseIdx: number; firstMeasure: Dose; lastMeasure: Dose } | null {
        return this._sawchukZaskePair;
    }

    public get pkParamModel(): PkParamModel {
        return this._pkParamModel;
    }

    public get administeredDoses(): Record<string, AdministeredDose[]> {
        return this._administeredDoses;
    }

    public get administeredDoseConc(): Record<string, ConcTime[]> {
        return this._administeredDoseConc;
    }

    public get plansByDeliveryMethod(): Record<string, DynamicDosePlan[]> {
        return this._plansByDeliveryMethod;
    }

    public plansForDeliveryMethod(deliveryMethod: string): DynamicDosePlan[] {
        if (deliveryMethod in this._plansByDeliveryMethod){
            return this._plansByDeliveryMethod[deliveryMethod];
        }
        return [];
    }

    public adminDoseConcForType(calcType: string): ConcTime[] {
        if (calcType in this._administeredDoseConc){
            return this._administeredDoseConc[calcType]
        }
        return [];
    }

    public get selectedPlan(): DynamicDosePlan | null {
        return this._selectedPlan;
    }

    public set selectedPlan(plan: DynamicDosePlan | null){
        if (plan){
            if (this._target){
                plan.calculateHighlights(this._target)
            }
            plan.calculateTimeSeries(this);
        }
        this._selectedPlan = plan;
    }

    public isReady(): boolean {
        return this.canGeneratePlans();
    }

    private generateDosesByType() {
        for (const dose of this._doses){
            const type = dose.type;
            if (type in this._dosesByType){
                this._dosesByType[type].push(dose);
            }
            else {
                this._dosesByType[type] = [dose];
            }
        }
    }
    private hasCreatinineEntry():boolean {
        return DoseTypes.SECR in this._dosesByType;
    }

    private hasCreatinineClearanceEntry():boolean {
        return DoseTypes.CLCR in this._dosesByType;
    }

    private canGeneratePlans(): boolean{
        const havePopulatedPatient = this._patient != null && this._patient.isPopulated();
        const havePopulatedTarget = this._target != null && this._target.isPopulated();
        const havePopulatedDelivery = this._delivery != null && this._delivery.isPopulated();
        const haveModel = this._model != null;
        const haveCreatine = (this.hasCreatinineEntry() || this.hasCreatinineClearanceEntry());
        // console.log(`canGeneratePlans havePopulatedPatient=${havePopulatedPatient} havePopulatedTarget=${havePopulatedTarget} havePopulatedDelivery=${havePopulatedDelivery} haveModel=${haveModel} haveCreatine=${haveCreatine}`);
        const result = havePopulatedPatient && havePopulatedTarget && havePopulatedDelivery && haveModel && haveCreatine;
        return result;
    }

    public hasAdministeredDoses():boolean {
        return DoseTypes.DOSE in this._dosesByType;
    }

    public hasMeasured(): boolean {
        return DoseTypes.CONC in this._dosesByType;
    }

    private getDoseIndexForHr(dt: number) {
        const dosesSorted = this._dosesByType[DoseTypes.DOSE];
        if (dosesSorted) {
            const startTs = dosesSorted[0].datetime.getTime();
            let doseNum = 0;
            for (const doseToTest of dosesSorted) {
                const startHr = (doseToTest.datetime.getTime() - startTs) / (1000*60*60);
                if (startHr > dt) {
                    break; // gone past
                }
                doseNum++;
            }
            if (doseNum >= dosesSorted.length) {
                // went off end
                doseNum = dosesSorted.length - 1;
            }
            return doseNum - 1;
        }
        return 0;
    }

    private getDoseIndexForHrFromAdministered(administeredDoses: AdministeredDose[], dt: number) {
        let doseNum = 0;
        for (const adminDose of administeredDoses) {
            if (adminDose.startHr > dt) {
                break; // gone past
            }
            doseNum++;
        }
        if (doseNum >= administeredDoses.length) {
            // went off end
            doseNum = administeredDoses.length - 1;
        }
        else {
            doseNum--;
        }
        return doseNum;
    }

    private generateSawchukZaskePair() {
        console.log("generateSawchukZaskePair");
        if (!(this.hasAdministeredDoses() && this.hasMeasured())){
            // no data
            return;
        }

        const doses: Dose[] = this._dosesByType[DoseTypes.DOSE];
        const measured: Dose[] = this._dosesByType[DoseTypes.CONC];

        const startdt = doses[0].datetime.getTime();
        let currentFoundDose = -1;
        let firstMeasureInDose = null;
        let lastMeasureInDose = null;
        const candidates = [];
        for (let measurement of measured) {
            const hrsSinceStart = (measurement.datetime.getTime() - startdt) / (1000.0 * 60 * 60);
            const doseOfMeasurement = this.getDoseIndexForHr(hrsSinceStart);
            if (doseOfMeasurement == 1 || doseOfMeasurement == 2){
                // not considered valid
                continue;
            }
            if (doseOfMeasurement != currentFoundDose){
                if (firstMeasureInDose != null && lastMeasureInDose != null){
                    // we found a candidate
                    candidates.push({
                        dose: doses[currentFoundDose],
                        doseIdx: currentFoundDose,
                        firstMeasure: firstMeasureInDose,
                        lastMeasure: lastMeasureInDose,
                        // approximate interval by taking time to this dose and dividing by how many doses so far.
                        interval: currentFoundDose > 0 ? ((doses[currentFoundDose].datetime.getTime() - startdt) / (1000.0 * 60 * 60))/(currentFoundDose) : 0
                    });
                }
                currentFoundDose = doseOfMeasurement;
                firstMeasureInDose = measurement;
                lastMeasureInDose = null;
            }
            else {
                lastMeasureInDose = measurement;
            }
        }
        if (firstMeasureInDose != null && lastMeasureInDose != null){
            // we found a candidate
            candidates.push({
                dose: doses[currentFoundDose],
                doseIdx: currentFoundDose,
                firstMeasure: firstMeasureInDose,
                lastMeasure: lastMeasureInDose,
                // approximate interval by taking time to this dose and dividing by how many doses so far.
                interval: currentFoundDose > 0 ? ((doses[currentFoundDose].datetime.getTime() - startdt) / (1000.0 * 60 * 60))/(currentFoundDose) : 0
            })
        }

        if (candidates.length > 0){
            this._sawchukZaskePair = candidates[candidates.length - 1];
        }
    }

    // Calculate creatinine clearance from serum creatinine by CockcroftGault
    // @params sim Simulation
    // return number (mL/min)
    // Ref: https://www.mdcalc.com/calc/43/creatinine-clearance-cockcroft-gault-equation#evidence
    // mL/min = (140 – age) × (weight, kg) × (0.85 if female) / (72 × Cr, mg/dL)
    // Convert: 1 mg/dL = 0.01131222 µmol/L
    private calculateCockcroftGault(): number | null{
        console.log('Using CockcroftGault method');
        let crcl = null;

        if (this._patient && this._patient.age && this.hasCreatinineEntry() && this._model) {
            const genderFactor = this._patient.gender === 'Female' ? 0.85 : 1;
            const ageYears = this._patient.ageUnit === 'years' ? this._patient.age : this._patient.age / 365.25;
            const crDose = this._dosesByType[DoseTypes.SECR][0];
            if (crDose && crDose.amount) {
                // scr in mg/dL
                let scr = crDose.amountUnit === 'umol/L' ? crDose.amount * CONVERT_TO_MGDL : crDose.amount * 1;
                // Correction for Buelga 2005 which uses conventional scr calc
                scr = this._model.correctSCR(scr);
                // Adjust for BMI
                const bmi = this._patient.bmi();
                if (bmi) {
                    const weight = bmi < 18.5 ? this._patient.weightKg(false) : bmi < 25 ? this._patient.weightKg(true) : this._patient.weightKg(true, true);
                    if (weight != null){
                        crcl = (140 - ageYears) * weight * genderFactor / (72 * scr);
                        if (DEBUG) {
                            console.log(`CockcroftGault: creatinine: ${crDose.amount} applied weight: ${weight}kg -> crcl=${crcl}`);
                        }
                    }
                }
                // Cap with model capCrcl
                const cap = this._model.default.capCrcl;
                if (cap && crcl && crcl > cap) {
                    crcl = cap;
                    if (DEBUG) {
                        console.log(`CockcroftGault: CrCl is capped: crcl=${crcl}`);
                    }
                }
            }
        }
        return crcl;
    }

    private generatePopulationPK(crcl: number){
        const pkparams: PkParam[] = []
        if (this._patient && this._model) {
            const weight = this._patient.weightKg(false, false)
            if (weight) {
                // Patient pkparams
                const bsa = this._patient.bsa() ?? 1
                pkparams.push(new PkParam({
                    key: 'ideal',
                    label: 'BW Ideal',
                    value: this._patient.weightKg(true),
                    unit: 'kg',
                    type: PkType.PATIENT
                }))
                pkparams.push(new PkParam({
                    key: 'adjusted',
                    label: 'BW Adjusted',
                    value: this._patient.weightKg(true, true),
                    unit: 'kg',
                    type: PkType.PATIENT
                }))
                pkparams.push(new PkParam({
                    key: 'bmi',
                    label: 'BMI',
                    value: this._patient.bmi(),
                    unit: '',
                    type: PkType.PATIENT
                }))
                pkparams.push(new PkParam({
                    key: 'bsa',
                    label: 'BSA',
                    value: bsa,
                    unit: 'm2',
                    type: PkType.PATIENT
                }))
                pkparams.push(new PkParam({
                    key: 'crcl',
                    label: 'Creatinine CL',
                    value: crcl.toFixed(2),
                    unit: 'mL/min',
                    type: PkType.PATIENT
                }))

                // One or more compartment models
                if (this._model.getCompartment() === 1) {
                    let Vd: number = this._model.vd[0].coeff * weight;
                    // Clearance of drug based on model coeff and patient crcl
                    let drugCl: number = this._model.drugClearance(crcl, bsa);
                    // apply interindividual variability
                    if (this._delivery?.applyInterindividualVariability){
                        Vd = Vd * Math.exp(this._model.vd[0].sd * this._model.vd[0].sd);
                        // apply interindividual variability
                        drugCl = drugCl * Math.exp(this._model.clearance[0].sd * this._model.clearance[0].sd)
                    }
                    // const Vd: number = this._model.vd[0].coeff * weight;
                    const Vd_sd: number = Vd * this._model.vd[0].sd
                    const drugCl_sd: number = drugCl * this._model.clearance[0].sd
                    const kel: number = drugCl / Vd
                    const halfLife: number = (0.693 * Vd) / drugCl
                    pkparams.push(new PkParam({
                        key: 'drugcl',
                        label: 'Drug CL',
                        value: drugCl.toFixed(2),
                        sd: drugCl_sd.toFixed(2),
                        unit: 'L/hr',
                        type: PkType.EMPIRIC
                    }))
                    pkparams.push(new PkParam({
                        key: 'vd',
                        label: 'Vd',
                        value: Vd.toFixed(2),
                        sd: Vd_sd.toFixed(2),
                        unit: 'L',
                        type: PkType.EMPIRIC
                    }))
                    pkparams.push(new PkParam({
                        key: 'kel',
                        label: 'Kel',
                        value: kel.toFixed(4),
                        unit: '/hr',
                        type: PkType.EMPIRIC
                    }))
                    pkparams.push(new PkParam({
                        key: 't12',
                        label: 'Half-life',
                        value: halfLife.toFixed(2),
                        unit: 'hrs',
                        type: PkType.EMPIRIC
                    }))
                    if (DEBUG) {
                        console.log(`generatePk: Population one-cpt drugCl=${drugCl}ml/hr vd=${Vd} kel=${kel}`)
                    }
                } else if (this._model.getCompartment() === 2 && this._model.vd.length === 2) {
                    const v1: Pk = this._model.vd[0]
                    const Vc: number = v1.coeff
                    const Vc_sd: number = v1.sd
                    const v2: Pk = this._model.vd[1]
                    const Vp: number = v2.coeff
                    const Vp_sd: number = v2.sd
                    const drugCl2 = this._model.clearance.filter((c) => c.type === 'central')[0]
                    const Q: number = this._model.clearance.filter((c) => c.type === 'intercompartmental')[0].coeff
                    const beta: number = calculateBeta(Q, Vc, Vp, drugCl2.coeff)
                    const alpha: number = calculateAlpha(Q, Vc, Vp, drugCl2.coeff, beta)
                    const A: number = calculateACompartment(Q, Vc, Vp, alpha, beta)
                    const B: number = calculateBCompartment(Q, Vc, Vp, alpha, beta)
                    pkparams.push(new PkParam({
                        key: 'drugcl',
                        label: 'Drug CL',
                        value: drugCl2.coeff.toFixed(2),
                        sd: drugCl2.sd.toFixed(2),
                        unit: 'L/hr',
                        type: PkType.EMPIRIC
                    }))
                    pkparams.push(new PkParam({
                        key: 'Vc',
                        label: 'Vd (central)',
                        value: Vc.toFixed(2),
                        sd: Vc_sd.toFixed(2),
                        unit: 'L',
                        type: PkType.EMPIRIC
                    }))
                    pkparams.push(new PkParam({
                        key: 'Vp',
                        label: 'Vd (peripheral)',
                        value: Vp.toFixed(2),
                        sd: Vp_sd.toFixed(2),
                        unit: 'L',
                        type: PkType.EMPIRIC
                    }))
                    pkparams.push(new PkParam({
                        key: 'Q',
                        label: 'Q (intercompartmental)',
                        value: Q.toFixed(2),
                        unit: 'L/h',
                        type: PkType.EMPIRIC
                    }))
                    pkparams.push(new PkParam({
                        key: 'alpha',
                        label: 'Alpha (distribution)',
                        value: alpha.toFixed(2),
                        unit: '',
                        type: PkType.EMPIRIC
                    }))
                    pkparams.push(new PkParam({
                        key: 'beta',
                        label: 'Beta (elimination)',
                        value: beta.toFixed(2),
                        unit: '',
                        type: PkType.EMPIRIC
                    }))
                    pkparams.push(new PkParam({
                        key: 'A',
                        label: 'A (distribution)',
                        value: A?.toFixed(2),
                        unit: '',
                        type: PkType.EMPIRIC
                    }))
                    pkparams.push(new PkParam({
                        key: 'B',
                        label: 'B (elimination)',
                        value: B?.toFixed(2),
                        unit: '',
                        type: PkType.EMPIRIC
                    }))
                    if (DEBUG) {
                        console.log(`generatePk: Population 2-cpt drugCl=${drugCl2.coeff}ml/hr Vc=${Vc} Vp=${Vp} Q=${Q}`)
                    }
                } else {
                    throw Error('Unable to proceed with simulation - missing model info')
                }

                this._pkParamModel.addAllParams(pkparams);
            } else {
                console.error('No patient CrCl to generate pkParams')
                throw Error('Unable to proceed with simulation - missing Crcl')
            }
        } else {
            console.error('No patient weight to generate pkParams')
            throw Error('Unable to proceed with simulation - missing weight')
        }
    }

    private hasSawchukInterval() {
        return this._sawchukZaskePair != null;
    }


    // Calculate Kel from two concentrations over time
    // @params conc1: first concentration (mcg/ml)
    // @params conc2: second concentration (mcg/ml)
    // @params dt: time difference (hr)
    calculateKelFromConcentrations(conc1: number, conc2: number, dt: number): number {
        return Math.log(conc1 / conc2) / dt
    }

    // Sawchuk-Zaske method for 2 measured concns at initial or steady state (> 3 doses)
    // NB: this does not use any model population values
    calculatePkBySawchukZaske(): null | {kel: string, vd: string, cl: string, half: string}{
        let rtn: null | {kel: string, vd: string, cl: string, half: string} = null
        if (this._sawchukZaskePair == null) {
            return null;
        }

        console.log(`SawchukZaske method for fitting`);
        const idx = this._sawchukZaskePair.doseIdx;
        // use first interval or after 3 or more doses (ss)
        if (idx === 0 || idx > 2) {
            const idx = this._sawchukZaskePair.doseIdx;
            const dose: Dose = this._sawchukZaskePair.dose;
            const m0: Dose = this._sawchukZaskePair.firstMeasure;                  // detect first measured in interval
            const m1: Dose = this._sawchukZaskePair.lastMeasure;    // detect last measured in interval
            const dt: number = (m1.datetime.getTime() - m0.datetime.getTime()) / (1000.0 * 60 * 60);
            // Determine slope (Kel) from measured concentrations: ln(C0/C1) / dt
            const kel: number = this.calculateKelFromConcentrations(m0.amount, m1.amount, dt)
            const t_half: number = 0.693 / kel
            // For Vd, Extrapolate to Cmax and Cmin by subtracting the time of first conc from time of dose + infusion
            // Cp=Cp0 * exp(-k.t)
            // Find cmax
            const endInfusion = addDate(dose.datetime, dose.duration, dose.durationUnit);
            // @ts-ignore
            const t1: number = (new Date(m0.datetime) - endInfusion) / (1000.0 * 60 * 60) //ms to hr
            const cmax: number = m0.amount / Math.exp(-kel * t1)
            // calculated trough from measured trough at time before start of next infusion
            // @ts-ignore
            // If this is the first dose, set cmin to 0 (ie, not at ss)
            let cmin = 0;
            if (idx !== 0) {
                // calculate a cmin
                const endInterval = addDate(dose.datetime, this._sawchukZaskePair.interval, "hours");
                const timeToDecay = (endInterval.getTime() - m1.datetime.getTime()) / (1000.0 * 60 * 60);
                cmin = m1.amount * Math.exp(-kel * timeToDecay);
            }
            const vd: number = (dose.amount / dose.duration * (1 - Math.exp(-kel * dose.duration))) / (kel * (cmax - (cmin * Math.exp(-kel * dose.duration))))
            // recalculate with new vd, kel
            const cl: number = kel * vd
            if (DEBUG) {
                console.log(`SawchukZaske - patient m0: ${m0.amount} t0: ${t1} m1: ${m1.amount} t1: ${t1} dt: ${dt} cl: ${cl}ml/hr vd: ${vd} cmax: ${cmax} cmin: ${cmin}`)
            }
            rtn = {
                kel: kel.toFixed(4),
                vd: vd.toFixed(2),
                cl: cl.toFixed(2),
                half: t_half.toFixed(2)
            }
        }
        return rtn;
    }

    // Get Data as object with x,y arrays where x = time since end of infusion (hrs), y is measured conc (mg/ml)
    // @param doses : array of Dose with at least one dose and drug level - SORTED in ascending by datetime (removed from here bs very slow)
    // @return: params object with estimates of vd (vd_est), cl (cl_est), kel (kel_est), halflife
    // OR null if not enough doses provided
    private getXYData(): {x: number[], y: number[]} | null {
        const drugLevels = this._dosesByType[DoseTypes.CONC];
        const dosesSorted = this._dosesByType[DoseTypes.DOSE];
        if (dosesSorted.length >= 1 && drugLevels.length >= 1) {
            let startTime = new Date(dosesSorted[0].datetime)
            // const duration_hr = dosesSorted[0].getInfusionDurationInHours();
            const x_arr = []
            const y_arr = []

            for (let i = 0; i < drugLevels.length; i++) {
                let dt = ((drugLevels[i].datetime.getTime() - startTime.getTime()) / (1000.0 * 60 * 60)) // - duration_hr
                if (!isNaN(dt) && dt > 0) {
                    x_arr.push(dt);
                    y_arr.push(drugLevels[i].amount);
                } else {
                    console.error('Error in getXYData values');
                    return null;
                }
            }
            return {
                x: x_arr,
                y: y_arr
            };
        } else {
            return null;
        }
    }


    // Calculate the concentration at a given time (dt)
    // @param vd: vol dist
    // @param cl: drug clearance
    // @param dt: time at which concentration required (hours from time of c0)
    private calculateConcentrationAtTime(administeredDoses: AdministeredDose[], calculator: ConcentrationCalculator, dt: number) {
        const doseToUse = administeredDoses[this.getDoseIndexForHrFromAdministered(administeredDoses, dt)];

        const infusionEndHr = doseToUse.startHr + doseToUse.infusionHrs;
        if (dt == infusionEndHr){
            return doseToUse.infusionEndConcentration;
        }
        else if (dt < infusionEndHr){
            // inside infusion period
            const hrsToInfuse = dt - doseToUse.startHr;
            return calculator.concentrationDuringInfusion(doseToUse.startConcentration, doseToUse.getDosePerHour(), hrsToInfuse);
        }
        else {
            // in decay perion
            const hrsToDecay = dt - infusionEndHr;
            return calculator.concentrationDuringDecay(doseToUse.infusionEndConcentration, hrsToDecay);
        }
    }

    // run Bayesian MCMC stepper to calculate pkParams from measured concentrations
    // @params pop_params : object with cl, vd
    // @params doses : all doses and measurements for patient
    // @returns params with pop_params and estimated params
    runBayesianFit(pop_params: {cl: number, vd: number, cl_sd: number, vd_sd: number}) : {cl: number, vd: number, cl_sd: number, vd_sd: number, vd_est?: number, cl_est?: number, kel_est?: number, halflife_est?:number} {

        const params = {
            cl: {type: "real", init: pop_params.cl},
            vd: {type: "real", init: pop_params.vd},
            sigma: {type: "real", lower: 0}
        };

        const data = this.getXYData();
        console.log(data);
        if (data) {
            const sample_iterations = 10;
            const samples_per_iteration = 10000;
            const samples_to_burn = 100;
            console.log(`Running Bayesian fit with ${samples_per_iteration} samples per iteration`)
            let samples;

            const arr_cl = [];
            const arr_vd = [];

            const options = {
                thin: 10,
                target_accept_rate: 0.6
            };

            const log_post = (state: {cl: number, vd: number, sigma: number}, data: {x: number[], y: number[]}) => {
                let log_post = 0;
                const k = state.cl / state.vd;
                const calculator = new OneCompartmentConcentrationCalculator(k, state.vd);
                const administeredDoses = this.calculateAdministered(calculator);

                // Priors
                log_post += norm(state.cl, pop_params.cl, pop_params.cl_sd);
                log_post += norm(state.vd, pop_params.vd, pop_params.vd_sd);
                log_post += unif(state.sigma, 0, 1);

                // Likelihood
                for (let i = 0; i < data.y.length; i++) {
                    const mu = this.calculateConcentrationAtTime(administeredDoses, calculator, data.x[i]);
                    // console.log(state.cl, state.vd, mu);
                    // if (DEBUG) {
                    //     console.log(`mu=${mu}`)
                    // }
                    const n = norm(data.y[i], mu, state.sigma);
                    log_post += n
                }
                if (isNaN(log_post)) {
                    console.error('Error - log_post is NaN', data)
                    log_post = 0;
                }

                return log_post;
            };

            for (let samp_i = 0; samp_i < sample_iterations; samp_i++) {
                const sampler = new AmwgSampler(params, log_post, data, options);
                sampler.burn(samples_to_burn);
                samples = sampler.sample(samples_per_iteration);
                // Capture mean of output
                arr_cl.push(ss.mean(samples["cl"]));
                arr_vd.push(ss.mean(samples["vd"]));
            }
            const vd_est = parseFloat(ss.mean(arr_vd).toFixed(2));
            const cl_est = parseFloat(ss.mean(arr_cl).toFixed(2));
            const kel_est = cl_est / vd_est;
            const halflife_est = 0.693 / kel_est;

            return {
                ...pop_params,
                vd_est,
                cl_est,
                kel_est: parseFloat(kel_est.toFixed(4)),
                halflife_est: parseFloat(halflife_est.toFixed(4))
            };
        } else {
            return pop_params
        }
    }

    calculatePkByBayesian(pop_clearance: PkParam, pop_vd: PkParam): null | {kel: string | undefined, vd: string | undefined, cl: string | undefined, half: string | undefined}{
        if (pop_clearance && pop_vd) {
            const pop_params = {
                cl: (typeof pop_clearance.value === "string") ? parseFloat(pop_clearance.value) : pop_clearance.value,
                cl_sd: (typeof pop_clearance.sd === "string") ? parseFloat(pop_clearance.sd) : pop_clearance.sd,
                vd: (typeof pop_vd.value === "string") ? parseFloat(pop_vd.value) : pop_vd.value,
                vd_sd: (typeof pop_vd.sd === "string") ? parseFloat(pop_vd.sd) : pop_vd.sd
            }
            // @ts-ignore
            const pkparams = this.runBayesianFit(pop_params)
            if (DEBUG) {
                console.log(pop_params, pkparams)
            }
            return {
                kel: pkparams.kel_est?.toFixed(4),
                vd: pkparams.vd_est?.toFixed(4),
                cl: pkparams.cl_est?.toFixed(4),
                half: pkparams.halflife_est?.toFixed(4)
            }
        }
        return null
    }

    // For one compartment models
    // Step 1a. If patient has measured concentrations,
    // re-estimate cl, kel and vd
    // using Bayesian MCMC stepper
    // works best with 1 or more measurements in a single dose interval
    generatePkFromMeasured() {
        const doses: Dose[] | null = this._dosesByType[DoseTypes.DOSE];
        const measured: Dose[] | null = this._dosesByType[DoseTypes.CONC];
        const pop_clearance = this._pkParamModel.getPk('drugcl')
        const pop_vd = this._pkParamModel.getPk('vd')
        if (pop_clearance && pop_vd && doses.length > 0 && measured.length > 0) {

            // to use Sawchuk Zaske we need 2 measurements in the same dose after infusion
            // for best results closer to steady state is better.
            // Sawchuk if 2 m in same interval for dose=1 or > 3 else Bayesian fit
            const pk: {kel: string | undefined, vd: string | undefined, cl: string | undefined, half: string | undefined} | null = this.hasSawchukInterval() ?
                this.calculatePkBySawchukZaske() :
                this.calculatePkByBayesian(pop_clearance, pop_vd)
            if (pk) {
                if (DEBUG) {
                    console.log(`generatePkFromMeasured - optimised patient cl: ${pk.cl}ml/hr vd: ${pk.vd} kel: ${pk.kel}`)
                }

                this._pkParamModel.addParam(new PkParam({
                    key: 'drugcl_p',
                    label: 'Drug CL',
                    value: pk.cl,
                    unit: 'L/hr',
                    type: PkType.OPTIMISED
                }))
                this._pkParamModel.addParam(new PkParam({
                    key: 'vd_p',
                    label: 'Vd',
                    value: pk.vd,
                    unit: 'L',
                    type: PkType.OPTIMISED
                }))
                this._pkParamModel.addParam(new PkParam({
                    key: 'kel_p',
                    label: 'Kel',
                    value: pk.kel,
                    unit: '/hr',
                    type: PkType.OPTIMISED
                }))
                this._pkParamModel.addParam(new PkParam({
                    key: 't12_p',
                    label: 'Half-life',
                    value: pk.half,
                    unit: 'hrs',
                    type: PkType.OPTIMISED
                }))
            }

        }
    }

    private runBayesianFit2(pop_params: {
        q: number,
        cl: number,
        vc: number,
        vp: number,
        vc_sd: number,
        vp_sd: number
    }): {
        q: number, cl: number, sigma?: number, vc: number, vp: number, vc_sd: number, vp_sd: number, vc_est?: number,
        vp_est?: number, a_est?: number, b_est?: number, alpha?: number, beta?: number
    } {

        const params = {
            vc: {type: "real", init: pop_params.vc},
            vp: {type: "real", init: pop_params.vp},
            sigma: {type: "real", lower: 0}
        };

        const q = pop_params.q
        const cl = pop_params.cl

        const data = this.getXYData();
        if (data) {
            const sample_iterations = 5;
            const samples_per_iteration = 10000;
            const samples_to_burn = 100;
            console.log(`Running Bayesian fit 2 with ${samples_per_iteration} samples per iteration`)
            let samples;

            const arr_vc = [];
            const arr_vp = [];

            const options = {
                thin: 10,
                target_accept_rate: 0.6
            };

            const log_post = (state: {q: number, cl: number, sigma: number, vc: number, vp: number, vc_sd: number, vp_sd: number}, data: {x: number[], y: number[]}) => {
                let log_post = 0;

                // Priors
                log_post += norm(state.vc, pop_params.vc, pop_params.vc_sd);
                log_post += norm(state.vp, pop_params.vp, pop_params.vp_sd);
                log_post += unif(state.sigma, 0, 1);

                const beta = calculateBeta(q, state.vc, state.vp, cl);
                const alpha = calculateAlpha(q, state.vc, state.vp, cl, beta)
                const A = calculateACompartment(q, state.vc, state.vp, alpha, beta)
                const B = calculateBCompartment(q, state.vc, state.vp, alpha, beta)

                const calculator = new TwoCompartmentConcentrationCalculator(alpha, beta, A, B);
                const administeredDoses = this.calculateAdministered(calculator);

                // Likelihood
                for (let i = 0; i < data.y.length; i++) {
                    const mu = this.calculateConcentrationAtTime(administeredDoses, calculator, data.x[i]);
                    if (DEBUG) {
                        console.log(`mu=${mu}`)
                    }
                    const n = norm(data.y[i], mu, state.sigma);
                    log_post += n
                }
                if (isNaN(log_post)) {
                    console.error('Error - log_post is NaN', data)
                    log_post = 0;
                }

                return log_post;
            };

            for (let samp_i = 0; samp_i < sample_iterations; samp_i++) {
                const sampler = new AmwgSampler(params, log_post, data, options);
                sampler.burn(samples_to_burn);
                samples = sampler.sample(samples_per_iteration);
                // Capture mean of output
                arr_vc.push(ss.mean(samples["vc"]));
                arr_vp.push(ss.mean(samples["vp"]));
            }
            const vc_est = parseFloat(ss.mean(arr_vc).toFixed(2));
            const vp_est = parseFloat(ss.mean(arr_vp).toFixed(2));
            const beta = calculateBeta(q, vc_est, vp_est, cl)
            const alpha = calculateAlpha(q, vc_est, vp_est, cl, beta)
            const a_est = calculateACompartment(q, vc_est, vp_est, alpha, beta)
            const b_est = calculateBCompartment(q, vc_est, vp_est, alpha, beta)

            return {
                ...pop_params,
                vc_est,
                vp_est,
                a_est,
                b_est,
                alpha,
                beta
            };
        } else {
            return pop_params
        }
    }


    private calculatePkByBayesian2(pop_vc: PkParam, pop_vp: PkParam, pop_cl: number, pop_q: number): {vc: number, vp: number, alpha: number, beta: number, A: number, B: number} | null {
        if (pop_vc && pop_vp) {
            const pop_params = {
                vc: pop_vc.getValueOr(NaN), // should never use default
                vc_sd: pop_vc.getSdOr(NaN),
                vp: pop_vp.getValueOr(NaN), // should never use default
                vp_sd: pop_vp.getSdOr(NaN),
                q: pop_q,
                cl: pop_cl
            }
            const params = this.runBayesianFit2(pop_params)
            if (DEBUG) {
                console.log(params)
            }
            return {
                vc: params.vc_est ?? NaN,
                vp: params.vp_est ?? NaN,
                A: params.a_est ?? NaN,
                alpha: params.alpha ?? NaN,
                B: params.b_est ?? NaN,
                beta: params.beta ?? NaN
            }
        }
        return null
    }


    // For 2 compartment models
    // If patient has measured concentrations - re-estimate A, alpha, B, beta - Bayesian only
    generatePkFromMeasured2() {
        const doses: Dose[] = this.hasAdministeredDoses() ? this._dosesByType[DoseTypes.DOSE] : [];
        const measured: Dose[] = this.hasMeasured() ? this._dosesByType[DoseTypes.CONC] : [];
        const pop_vc = this._pkParamModel.getPk('Vc')
        const pop_vp = this._pkParamModel.getPk('Vp')
        const pop_q = this._pkParamModel.getPkValue('Q')
        const pop_cl = this._pkParamModel.getPkValue('drugcl')
        if (doses.length > 0 && measured.length > 0 && pop_vc && pop_vp && pop_q && pop_cl) {
            const pk = this.calculatePkByBayesian2(pop_vc, pop_vp, pop_cl, pop_q)
            if (pk) {
                if (DEBUG) {
                    console.log(`generatePkFromMeasured2compartment optimised vc: ${pk.vc} vp: ${pk.vp} A: ${pk.A} alpha: ${pk.alpha} B: ${pk.B} beta: ${pk.beta}`)
                }

                this._pkParamModel.addParam(new PkParam({
                    key: 'Vc_p',
                    label: 'Vd (central)',
                    value: pk.vc,
                    unit: 'L',
                    type: PkType.OPTIMISED
                }))
                this._pkParamModel.addParam(new PkParam({
                    key: 'Vp_p',
                    label: 'Vd (peripheral)',
                    value: pk.vp,
                    unit: 'L',
                    type: PkType.OPTIMISED
                }))
                this._pkParamModel.addParam(new PkParam({
                    key: 'A_p',
                    label: 'A (distribution)',
                    value: pk.A,
                    unit: '',
                    type: PkType.OPTIMISED
                }))
                this._pkParamModel.addParam(new PkParam({
                    key: 'alpha_p',
                    label: 'Alpha (distribution)',
                    value: pk.alpha,
                    unit: '',
                    type: PkType.OPTIMISED
                }))
                this._pkParamModel.addParam(new PkParam({
                    key: 'B_p',
                    label: 'B (elimination)',
                    value: pk.B,
                    unit: '',
                    type: PkType.OPTIMISED
                }))
                this._pkParamModel.addParam(new PkParam({
                    key: 'beta_p',
                    label: 'Beta (elimination)',
                    value: pk.beta,
                    unit: '',
                    type: PkType.OPTIMISED
                }))
            }
        }
    }

    private generatePkParamModel(){
        const crcl: number | null = this.hasCreatinineEntry() ? this.calculateCockcroftGault() : this._dosesByType[DoseTypes.CLCR][0]?.amount
        if (crcl) {
            // 1. use population model and patient CrCl to calculate Kel, Vd
            this.generatePopulationPK(crcl)
            // 2. if patient has measured doses, re-estimate Kel, Vd from plasma concentration/s
            if (this.hasMeasured() && this._model) {
                if (this._model.getCompartment() === 1) {
                    this.generatePkFromMeasured()
                } else if (this._model.getCompartment() === 2) {
                    this.generatePkFromMeasured2()
                }
            }
        }
    }

    private getCalculator(calculationType: CalculationType): ConcentrationCalculator | null{
        if (this._model){
            const pkModifier = calculationType == CalculationType.POPULATION ? '' : '_p';
            if (this._model.getCompartment() === 1) {
                const kel = this._pkParamModel.getPkValue('kel' + pkModifier);
                const vd = this._pkParamModel.getPkValue('vd' + pkModifier);
                if (kel != null && vd != null){
                    return new OneCompartmentConcentrationCalculator(kel, vd);
                }
            }
            else {
                const alpha = this._pkParamModel.getPkValue('alpha' + pkModifier);
                const beta = this._pkParamModel.getPkValue('beta' + pkModifier);
                const A = this._pkParamModel.getPkValue('A' + pkModifier);
                const B = this._pkParamModel.getPkValue('B' + pkModifier);
                if (alpha != null && beta != null && A != null && B != null){
                    return new TwoCompartmentConcentrationCalculator(alpha, beta, A, B);
                }
            }
        }
        return null;
    }

    private calculateAdministered(concCalculator: ConcentrationCalculator): AdministeredDose[]{

        const result: AdministeredDose[] = []



        if (DoseTypes.DOSE in this._dosesByType && this._dosesByType[DoseTypes.DOSE].length > 0){
            let startConcentration = 0.0;
            let lastAdminDose: AdministeredDose | null = null;
            const startTime = this._dosesByType[DoseTypes.DOSE][0].datetime.getTime();
            for (const dose of this._dosesByType[DoseTypes.DOSE]){
                if (lastAdminDose){
                    // work out decay to the start of this dose.
                    const hrsToDecay = (dose.datetime.getTime() - lastAdminDose.infusionEndDt.getTime()) / (1000.0 * 60 * 60);
                    startConcentration = concCalculator.concentrationDuringDecay(lastAdminDose.infusionEndConcentration, hrsToDecay);
                }
                const infusionInHrs = dose.getInfusionDurationInHours();
                const dosePerHr = dose.amount / infusionInHrs;
                const infusionEndConcentration =  concCalculator.concentrationDuringInfusion(startConcentration, dosePerHr, infusionInHrs);
                lastAdminDose = new AdministeredDose(dose, startConcentration, infusionEndConcentration,
                    addDate(dose.datetime, dose.duration, dose.durationUnit), infusionInHrs,
                    (dose.datetime.getTime() - startTime) / (1000.0 * 60 * 60)
                );
                result.push(lastAdminDose);
            }
        }


        return result;
    }

    private calculateAdminConcentrations(calculationType: CalculationType, adminDoses: AdministeredDose[], concCalculator: ConcentrationCalculator): ConcTime[]{
        const result: ConcTime[] = [];

        let doseNum = 0;
        for (const dose of adminDoses){
            const infusionsStart = dose.startHr
            const start = dose.dose.datetime;
            const startConc = dose.startConcentration;
            const dosePerHr = dose.getDosePerHour();

            // add concentration at start.
            result.push(new ConcTime({
                interval: 'administered',
                time: new Date(start),
                concentration: startConc,
                concentrationUnit: 'mcg/mL',
                calculation: calculationType}));

            let hrToCalculate = Math.ceil(infusionsStart);
            const endHour = infusionsStart + dose.infusionHrs;
            if ((hrToCalculate - infusionsStart) < 0.01){
                //pretty much overlaps
                hrToCalculate++;
            }
            while (hrToCalculate < (endHour)){
                const infusionTime = hrToCalculate - infusionsStart
                const time = moment(start).add(infusionTime, "hours").toDate()
                const concentration = concCalculator.concentrationDuringInfusion(startConc, dosePerHr, infusionTime);
                result.push(new ConcTime({
                    interval: 'administered',
                    time: new Date(time),
                    concentration: concentration,
                    concentrationUnit: 'mcg/mL',
                    calculation: calculationType
                }));
                hrToCalculate += 1;
            }

            // add infusion end
            result.push(new ConcTime({
                interval: 'administered',
                time: new Date(dose.infusionEndDt),
                concentration: dose.infusionEndConcentration,
                concentrationUnit: 'mcg/mL',
                calculation: calculationType}));

            if (doseNum < (adminDoses.length - 1)){
                const endHour = adminDoses[doseNum + 1].startHr;
                const infusionEnd = dose.startHr + dose.infusionHrs;
                const start = dose.infusionEndDt;
                const startConcentration = dose.infusionEndConcentration;
                let hrToCalculate = Math.ceil(infusionEnd);
                if ((hrToCalculate - infusionEnd) < 0.01){
                    //pretty much overlaps
                    hrToCalculate++;
                }
                while (hrToCalculate < (endHour)){
                    const decayTime = hrToCalculate - infusionEnd
                    const time = moment(start).add(decayTime, "hours").toDate()
                    const concentration = concCalculator.concentrationDuringDecay(startConcentration, decayTime);
                    result.push(new ConcTime({
                        interval: 'administered',
                        time: new Date(time),
                        concentration: concentration,
                        concentrationUnit: 'mcg/mL',
                        calculation: calculationType
                    }));
                    hrToCalculate += 1;
                }
            }
            doseNum++;
        }
        return result;
    }

    private generateAdministeredDoseConcentrations(){
        if (this._model){
            console.log("generateAdministeredDoseConcentrations");

            const popCalculator = this.getCalculator(CalculationType.POPULATION);
            const patCalculator = this.getCalculator(CalculationType.PATIENT);
            if (popCalculator != null){
                this._administeredDoses[CalculationType.POPULATION] = this.calculateAdministered(popCalculator);
                this._administeredDoseConc[CalculationType.POPULATION] = this.calculateAdminConcentrations(CalculationType.POPULATION, this._administeredDoses[CalculationType.POPULATION], popCalculator);
            }
            if (patCalculator != null){
                this._administeredDoses[CalculationType.PATIENT] = this.calculateAdministered(patCalculator);
                this._administeredDoseConc[CalculationType.PATIENT] = this.calculateAdminConcentrations(CalculationType.PATIENT, this._administeredDoses[CalculationType.PATIENT], patCalculator);
            }
        }
    }

    private estimateLoadingDose(doseAmount: number, interval: number, infusionDuration: number): number | null {
        if (this.hasAdministeredDoses()){
            return null;
        }
        // estimate dose from model mg per kgdoseAmount
        const dosePerHour = doseAmount / infusionDuration
        const weight = this._patient?.weightKg() ?? null
        if (weight !== null && this._model != null && this._model.default != null){
            let estimatedDose: number | null = this._model?.default?.loadingDoseMgPerKg ? this._model.default.loadingDoseMgPerKg * weight :
                this._model?.default?.maintenanceDoseMgPerKg ? this._model.default.maintenanceDoseMgPerKg * weight : null
            if (estimatedDose && this._drug?.dosingUnit){
                // make in dosing unit amounts
                const numDoses = Math.ceil(estimatedDose / this._drug.dosingUnit);
                estimatedDose = numDoses * this._drug.dosingUnit;
            }
            return estimatedDose;
            if (estimatedDose) {
                return estimatedDose;
                // let estimateDosePerHour = estimatedDose / loadingDoseDuration;
                // if (this._drug?.maxDosePerHr && estimateDosePerHour > this._drug.maxDosePerHr) {
                //     estimatedDose = this._drug.maxDosePerHr * Math.floor(loadingDoseDuration);
                //     estimateDosePerHour = estimatedDose / loadingDoseDuration;
                // }
                // // console.log(`estimateLoadingDose doseAmout: ${doseAmount}, infusionDuration: ${infusionDuration}, loadingDoseDuration: ${loadingDoseDuration}, estimateDose: ${estimatedDose}, dosePerHour: ${dosePerHour}, estimateDosePerHour: ${estimateDosePerHour}`)
                // return dosePerHour < estimateDosePerHour ? estimatedDose : null
            }
        }
        return null;
    }

    private determineStartTime(interval: number): Date {
        if (this._delivery?.startDT){
            const minStart = this.minimumSimulationStart();
            if (minStart != null){
                if (this._delivery.startDT.getTime() >= minStart.getTime()){
                    return new Date(this._delivery.startDT);
                }
                console.error("Ignoring custom start before last administered dose");
            }
            else {
                return new Date(this._delivery.startDT);
            }
        }
        else if (this.hasAdministeredDoses()){
            // start at interval time after last dose
            const doses = this._dosesByType[DoseTypes.DOSE];
            const lastDose = doses[doses.length - 1];
            const lastDoseDT = lastDose.datetime;
            // can't have an interval smaller than infusion time.
            return addDate(lastDoseDT, Math.max(interval, lastDose.getInfusionDurationInHours()) , "hours");
        }
        return new Date(); // now
    }

    private estimateIVSummaryResult(calculator: ConcentrationCalculator, calculationType: CalculationType, doseAmount: number, interval: number, infusionDuration: number): SummaryResult {
        const mic = this._pathology?.mic ?? 1;
        if (calculator) {
            const estimatePeak = calculator.calculatePeak(interval, infusionDuration, doseAmount/infusionDuration);
            const estimateTrough = calculator.calculateTroughFromPeak(estimatePeak, interval, infusionDuration);
            const estimateAuc = calculator.calculateAucFromPeakAndTrough(estimatePeak, estimateTrough, interval, infusionDuration, mic);
            const estimateSS = doseAmount ? calculator.calculateSteadyState(doseAmount, interval, infusionDuration) : null;
            const tMic = calculator.calculatTmicFromPeak(estimatePeak, interval, infusionDuration, mic);
            const estimateTmic_percent = tMic && tMic < interval ? (tMic / interval) * 100 : 100

            return new SummaryResult(calculationType, estimateAuc, estimatePeak, estimateTrough, estimateSS, tMic, estimateTmic_percent);
        }
        console.error('input data not available to calculate summary result');
        return new SummaryResult(calculationType, 0, 0, 0, null, null, null);
    }

    private estimateContinousSummaryResult(calculator: ConcentrationCalculator, calculationType: CalculationType, doseAmount: number): SummaryResult {
        const mic = this._pathology?.mic ?? 1;
        if (calculator) {
            const estimatePeak = calculator.calculatePeak(24, 24, doseAmount);
            const estimateTrough = calculator.calculateTroughFromPeak(estimatePeak, 24, 24);
            const estimateAuc = calculator.calculateAucFromPeakAndTrough(estimatePeak, estimateTrough, 24, 24, mic);
            const estimateSS = doseAmount ? calculator.calculateSteadyState(doseAmount * 24, 24, 24) : null;
            const tMic = calculator.calculatTmicFromPeak(estimatePeak, 24, 24, mic);
            const estimateTmic_percent = tMic && tMic < 24 ? (tMic / 24) * 100 : 100

            return new SummaryResult(calculationType, estimateAuc, estimatePeak, estimateTrough, estimateSS, tMic, estimateTmic_percent);
        }
        console.error('input data not available to calculate summary result');
        return new SummaryResult(calculationType, 0, 0, 0, null, null, null);
    }

    private generateIVPlans(){
        if (this._drug == null) {
            return;
        }
        let method = null;
        for (const m of this._drug?.methods){
            if (m.type == DeliveryMethod.IV){
                method = m;
                break;
            }
        }
        if (method == null){
            return; // nothing to generate
        }

        const intervals: number[]|null|undefined = method?.intervalRange;
        const doseAmounts: number[]|null|undefined = method?.amountRange;
        const infusionDurations: number[]|null|undefined = method?.infusionRange;
        const maxDosePerHr: number|null|undefined =this._drug?.maxDosePerHr;

        console.log("Generate IV places with intervals, doseAmounts and infusion durations", intervals, doseAmounts, infusionDurations);

        if (intervals == null || doseAmounts == null || infusionDurations == null) {
            return; // nothing to generate
        }
        const generatedPlans: DynamicDosePlan[] = [];
        for (let interval of intervals) {
            for (let doseAmount of doseAmounts) {
                for (let infusionDuration of infusionDurations) {
                    if (maxDosePerHr && ((doseAmount/infusionDuration) > maxDosePerHr)){
                        continue; // dose is too high
                    }
                    // generate dose plan
                    const popCalculator = this.getCalculator(CalculationType.POPULATION);
                    const patCalculator = this.getCalculator(CalculationType.PATIENT)
                    const loadingDose = this.estimateLoadingDose(doseAmount, interval, infusionDuration);
                    const startTime = this.determineStartTime(interval);
                    let mainResult: SummaryResult | null = null;
                    let secondaryResult: SummaryResult | null = null;
                    if (patCalculator) {
                        mainResult = this.estimateIVSummaryResult(patCalculator, CalculationType.PATIENT, doseAmount, interval, infusionDuration);
                        if (popCalculator){
                            secondaryResult = this.estimateIVSummaryResult(popCalculator, CalculationType.POPULATION, doseAmount, interval, infusionDuration);
                        }
                    }
                    else if (popCalculator) {
                        mainResult = this.estimateIVSummaryResult(popCalculator, CalculationType.POPULATION, doseAmount, interval, infusionDuration);
                    }
                    else {
                        return; // failed to calculate anything
                    }

                    const doseRegime: DynamicDoseRegime = new DynamicDoseRegime(DeliveryMethod.IV, doseAmount,
                        loadingDose, infusionDuration, interval, startTime, mainResult?.auc, mainResult?.peak, mainResult?.trough, mainResult?.ss);

                    const plan = new DynamicDosePlan (doseRegime, mainResult, secondaryResult);
                    if (this._target){
                        plan.calculateHighlights(this._target);
                    }
                    generatedPlans.push(plan);
                }
            }
        }
        this._plansByDeliveryMethod[DeliveryMethod.IV] = generatedPlans;
    }

    private generateContinuousPlans(){
        if (this._drug == null) {
            return;
        }
        let method = null;
        for (const m of this._drug?.methods){
            if (m.type == DeliveryMethod.CONTINUOUS){
                method = m;
                break;
            }
        }
        if (method == null){
            return; // nothing to generate
        }
        const doseAmounts: number[]|null|undefined = method?.amountRange;
        if (doseAmounts == null) {
            return; // nothing to generate
        }
        const generatedPlans: DynamicDosePlan[] = [];
        for (let doseAmount of doseAmounts) {
            // generate dose plan
            const dosePerHour = doseAmount / 24.0;
            const popCalculator = this.getCalculator(CalculationType.POPULATION);
            const patCalculator = this.getCalculator(CalculationType.PATIENT)
            const loadingDose = this.estimateLoadingDose(dosePerHour, 24, 24);
            const startTime = this.determineStartTime(0);
            let mainResult: SummaryResult | null = null;
            let secondaryResult: SummaryResult | null = null;
            if (patCalculator) {
                mainResult = this.estimateContinousSummaryResult(patCalculator, CalculationType.PATIENT, dosePerHour);
                if (popCalculator){
                    secondaryResult = this.estimateContinousSummaryResult(popCalculator, CalculationType.POPULATION, dosePerHour);
                }
            }
            else if (popCalculator) {
                mainResult = this.estimateContinousSummaryResult(popCalculator, CalculationType.POPULATION, dosePerHour);
            }
            else {
                return; // failed to calculate anything
            }

            const doseRegime: DynamicDoseRegime = new DynamicDoseRegime(DeliveryMethod.CONTINUOUS, doseAmount,
                loadingDose, 24, 24, startTime, mainResult.auc, mainResult?.peak, mainResult?.trough, mainResult?.ss);

            const plan = new DynamicDosePlan (doseRegime, mainResult, secondaryResult);
            if (this._target){
                plan.calculateHighlights(this._target);
            }
            generatedPlans.push(plan);
        }
        this._plansByDeliveryMethod[DeliveryMethod.CONTINUOUS] = generatedPlans;
    }

    private generateDosePlans(){
        this.generateIVPlans();
        this.generateContinuousPlans();
    }


    addInfusionConcentrations(calculator: ConcentrationCalculator, start: Date, end: Date, dosePerHr: number,
                              startConc: number, includeFirst: boolean,
                              template: {interval: string, concentrationUnit: string, calculation?: string | null},
                              existing: ConcTime[] | null | undefined): ConcTime[] {
        const result: ConcTime[] = existing ?? [];

        if (includeFirst) {
            result.push(new ConcTime({
                interval: template.interval,
                time: new Date(start),
                concentration: startConc,
                concentrationUnit: template.concentrationUnit,
                calculation: template.calculation
            }));
        }
        let concentration = startConc;

        const hrsToDose = (end.getTime() - start.getTime()) / (1000.0 * 60 * 60);
        let hrsSinceStart = 1;

        while (hrsSinceStart < hrsToDose) {
            const time = moment(start).add(hrsSinceStart, "hours").toDate()
            let concentration = calculator.concentrationDuringInfusion(startConc, dosePerHr, hrsSinceStart);
            result.push(new ConcTime({
                interval: template.interval,
                time: new Date(time),
                concentration: concentration,
                concentrationUnit: template.concentrationUnit,
                calculation: template.calculation
            }));
            hrsSinceStart += 1;
        }
        // add final entry
        let finalConcentration = calculator.concentrationDuringInfusion(startConc, dosePerHr, hrsToDose);
        result.push(new ConcTime({
            interval: template.interval,
            time: new Date(end),
            concentration: finalConcentration,
            concentrationUnit: template.concentrationUnit,
            calculation: template.calculation
        }));
        return result;
    }

    addDecayConcentrations(calculator: ConcentrationCalculator, start: Date, end: Date, startConc: number,
                           template: {interval: string, concentrationUnit: string, calculation?: string | null},
                           existing: ConcTime[] | null | undefined): ConcTime[] {
        const result: ConcTime[] = existing ?? [];
        const hrsToDecay = (end.getTime() - start.getTime()) / (1000.0 * 60 * 60);
        let hrsSinceStart = 1;

        while (hrsSinceStart < hrsToDecay) {
            const time = moment(start).add(hrsSinceStart, "hours").toDate()
            let concentration = calculator.concentrationDuringDecay(startConc, hrsSinceStart);
            result.push(new ConcTime({
                interval: template.interval,
                time: new Date(time),
                concentration: concentration,
                concentrationUnit: template.concentrationUnit,
                calculation: template.calculation
            }));
            hrsSinceStart += 1;
        }
        // add final entry
        let finalConcentration = calculator.concentrationDuringDecay(startConc, hrsToDecay);
        result.push(new ConcTime({
            interval: template.interval,
            time: new Date(end),
            concentration: finalConcentration,
            concentrationUnit: template.concentrationUnit,
            calculation: template.calculation
        }));
        return result;
    }


    private calculateIVTimeSeries(calculator: ConcentrationCalculator, doseRegime: DynamicDoseRegime, calculationType: CalculationType, initialConcentration: number):ConcTime[] {

        if (calculator === null) {
            // data not available
            return [];
        }
        const result: ConcTime[] = [];
        let simulatatedHrs = 0;
        const hoursToSimulate = (this._delivery?.daysToDisplay ?? 3) * 24;
        const template = {
            interval: 'IV',
            concentrationUnit: 'mcg/mL',
            calculation: calculationType
        };
        if (!this.hasAdministeredDoses() && doseRegime.loadingDose) {
            // has loading dose. add it.
            const loadingDoseDuration = (this._drug?.maxDosePerHr) ? doseRegime.loadingDose / this._drug?.maxDosePerHr : doseRegime.infusionDuration;
            const loadingDosePerHour = (this._drug?.maxDosePerHr) ? this._drug?.maxDosePerHr : doseRegime.loadingDose / doseRegime.infusionDuration;
            const doseEndDate = moment(doseRegime.startTime).add(loadingDoseDuration, "hours").toDate();
            const decayEndDate = moment(doseRegime.startTime).add(doseRegime.interval, "hours").toDate();
            this.addInfusionConcentrations(calculator, doseRegime.startTime, doseEndDate,loadingDosePerHour, initialConcentration, true, template, result);
            this.addDecayConcentrations(calculator, doseEndDate, decayEndDate, result[result.length - 1].concentration, template, result);
            simulatatedHrs += doseRegime.interval;
        }
        const dosePerHr = doseRegime.dose / doseRegime.infusionDuration;
        console.log(`Calculate time series - interval = ${doseRegime.interval}, infusionDuration = ${doseRegime.infusionDuration}, dosePerHour = ${dosePerHr}`);
        while (simulatatedHrs < hoursToSimulate) {
            const isFirst = result.length === 0;
            const lastEntry = isFirst ? {time: doseRegime.startTime, concentration: initialConcentration} : result[result.length - 1];
            const doseStartDate = lastEntry.time;
            const startConc = lastEntry.concentration;
            const doseEndDate = moment(doseStartDate).add(doseRegime.infusionDuration, "hours").toDate();
            const decayEndDate = moment(doseStartDate).add(doseRegime.interval, "hours").toDate();
            this.addInfusionConcentrations(calculator, doseStartDate, doseEndDate, dosePerHr, startConc, result.length === 0, template, result);
            this.addDecayConcentrations(calculator, doseEndDate, decayEndDate, result[result.length - 1].concentration, template, result);
            simulatatedHrs += doseRegime.interval;
        }
        return result;
    }



    /*
     * Called from DynamicDosePlan to generate the predicted graph.
     */
    calculateTimeSeries(doseRegime: DynamicDoseRegime, calculationType: CalculationType): ConcTime[] {
        const calculator = this.getCalculator(calculationType);

        if (calculator === null) {
            // data not available
            return [];
        }

        let initialConcentration: number = 0.0
        let startTimeDt = doseRegime.startTime.getTime();
        if (this.hasAdministeredDoses()){
            const adminDosesConcentrations = this._administeredDoses[calculationType];
            const lastDoseConc = adminDosesConcentrations[adminDosesConcentrations.length - 1];
            const endOfDoseConc = lastDoseConc.infusionEndConcentration;
            const endOfDoseDt = lastDoseConc.infusionEndDt.getTime();
            initialConcentration = calculator.concentrationDuringDecay(endOfDoseConc, ((startTimeDt - endOfDoseDt)/(1000*60*60)))
        }
        if (doseRegime.deliveryMethod === DeliveryMethod.CONTINUOUS) {
            return this.calculateContinuousTimeSeries(calculator, doseRegime, calculationType, initialConcentration);
        }
        else if (doseRegime.deliveryMethod === DeliveryMethod.ORAL) {
            return this.calculateOralTimeSeries(calculator, doseRegime, calculationType, initialConcentration);
        }
        return this.calculateIVTimeSeries(calculator, doseRegime, calculationType, initialConcentration);
    }

    getAdministeredTimeSeries(calculationType: CalculationType, startTime: Date): ConcTime[] | null {
        if (!this.hasAdministeredDoses()){
            return null;
        }
        const calculator = this.getCalculator(calculationType);

        const givenDoses: ConcTime[] = this._administeredDoseConc[calculationType];
        if (givenDoses.length == 0 || calculator === null) {
            return null;
        }

        const lastDose: ConcTime = givenDoses[givenDoses.length - 1];
        const lastDoseTime: Date = lastDose.time;
        const lastDoseConc: number = lastDose.concentration;
        const template = {
            interval: 'administered',
            concentrationUnit: 'mcg/mL',
            calculation: calculationType
        };
        // decay the administered dose until the startTime
        const result = [...givenDoses];
        this.addDecayConcentrations(calculator, lastDoseTime, startTime, lastDoseConc, template, result);
        return result;
    }

    private calculateContinuousTimeSeries(calculator: ConcentrationCalculator, doseRegime: DynamicDoseRegime, calculationType: CalculationType, initialConcentration: number):ConcTime[] {

        if (calculator === null) {
            // data not available
            return [];
        }

        const result: ConcTime[] = [];
        const dosePerHr = doseRegime.dose / doseRegime.infusionDuration;
        const label = `${dosePerHr}mg/hr`;
        // push first
        result.push(new ConcTime({
            interval: label,
            time: new Date(doseRegime.startTime),
            concentration: initialConcentration,
            concentrationUnit: 'mcg/mL',
            calculation: calculationType
        }));

        let simulatedHrs = 1;
        const hoursToSimulate = (this._delivery?.daysToDisplay ?? 3) * 24;
        let concentration = initialConcentration;

        if (doseRegime.loadingDose){
            // apply loading dose over first 6 hours.
            const loadingDoseDuration = (this._drug?.maxDosePerHr) ? doseRegime.loadingDose / this._drug?.maxDosePerHr : 6;
            const loadingDosePerHour = (this._drug?.maxDosePerHr) ? this._drug?.maxDosePerHr : doseRegime.loadingDose / 6.0;
            while (simulatedHrs <= loadingDoseDuration && simulatedHrs < hoursToSimulate) {
                concentration = calculator.concentrationDuringInfusion(concentration, loadingDosePerHour, 1);
                const time = moment(doseRegime.startTime).add(simulatedHrs, "hours").toDate();
                result.push(new ConcTime({
                    interval: label,
                    time: time,
                    concentration: concentration,
                    concentrationUnit: 'mcg/mL',
                    calculation: calculationType
                }));
                simulatedHrs += 1;
            }
        }

        while (simulatedHrs < hoursToSimulate) {
            concentration = calculator.concentrationDuringInfusion(concentration, dosePerHr, 1);
            const time = moment(doseRegime.startTime).add(simulatedHrs, "hours").toDate();
            result.push(new ConcTime({
                interval: label,
                time: time,
                concentration: concentration,
                concentrationUnit: 'mcg/mL',
                calculation: calculationType
            }));
            simulatedHrs += 1;
        }
        return result;
    }

    private calculateOralTimeSeries(calculator: ConcentrationCalculator,  doseRegime: DynamicDoseRegime, calculationType: CalculationType, initialConcentration: number):ConcTime[] {
        const result: ConcTime[] = [];
        return result;
    }

    public calculateCustom(custom: Custom, addToSimulation: boolean): DynamicDosePlan | null {
        const popCalculator = this.getCalculator(CalculationType.POPULATION);
        const patCalculator = this.getCalculator(CalculationType.PATIENT)
        let startTime: Date | null | undefined = custom.startDt;
        let mainResult: SummaryResult | null = null;
        let secondaryResult: SummaryResult | null = null;
        let interval = convertToHours(custom.interval, custom.intervalUnit);
        let infusion = convertToHours(custom.duration, custom.durationUnit);
        let loadingDose = null;
        if (custom.method == DeliveryMethod.CONTINUOUS){
            if (custom.loading){
                loadingDose = this.estimateLoadingDose(custom.amount, 24, 24);
            }
            if (startTime == null){
                startTime = this.determineStartTime(0);
            }
            const dosePerHour = custom.amount / 24.0;
            if (patCalculator) {
                mainResult = this.estimateContinousSummaryResult(patCalculator, CalculationType.PATIENT, dosePerHour);
                if (popCalculator){
                    secondaryResult = this.estimateContinousSummaryResult(popCalculator, CalculationType.POPULATION, dosePerHour);
                }
            }
            else if (popCalculator) {
                mainResult = this.estimateContinousSummaryResult(popCalculator, CalculationType.POPULATION, dosePerHour);
            }
            else {
                return null; // failed to calculate anything
            }
        }
        else if (custom.method == DeliveryMethod.IV){
            if (custom.loading){
                loadingDose = this.estimateLoadingDose(custom.amount, interval, infusion) ;
            }
            if (startTime == null) {
                startTime = this.determineStartTime(custom.interval);
            }
            if (patCalculator) {
                mainResult = this.estimateIVSummaryResult(patCalculator, CalculationType.PATIENT, custom.amount, interval, infusion);
                if (popCalculator){
                    secondaryResult = this.estimateIVSummaryResult(popCalculator, CalculationType.POPULATION, custom.amount, interval, infusion);
                }
            }
            else if (popCalculator) {
                mainResult = this.estimateIVSummaryResult(popCalculator, CalculationType.POPULATION, custom.amount, interval, infusion);
            }
            else {
                return null; // failed to calculate anything
            }
        }
        else {
            console.error(`Error calculating custom dosing plan, don't know how to process method ${custom.method}`);
            return null; // failed to calculate anything
        }

        const doseRegime: DynamicDoseRegime = new DynamicDoseRegime(custom.method, custom.amount, loadingDose,
            custom.method == DeliveryMethod.CONTINUOUS ? 24 : infusion,
            custom.method == DeliveryMethod.CONTINUOUS ? 24 : interval,
            startTime, mainResult.auc, mainResult?.peak, mainResult?.trough, mainResult?.ss);

        const plan = new DynamicDosePlan (doseRegime, mainResult, secondaryResult);
        if (this._target){
            plan.calculateHighlights(this._target);
        }
        plan.calculateTimeSeries(this);

        if (addToSimulation){
            if (custom.method in this._plansByDeliveryMethod){
                this._plansByDeliveryMethod[custom.method].push(plan);
            }
            else {
                this._plansByDeliveryMethod[custom.method] = [plan];
            }
        }
        return plan;
    }

    public minimumSimulationStart(): Date | null {
        if (this.hasAdministeredDoses()){
            const dosesSorted = this._dosesByType[DoseTypes.DOSE];
            if (dosesSorted && dosesSorted.length > 0){
                const lastAdminDose = dosesSorted[dosesSorted.length - 1];

                return addDate(lastAdminDose.datetime, lastAdminDose.getInfusionDurationInHours(), 'hours');
            }
        }
        return null;
    }
}