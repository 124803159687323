<template>
  <div id='custom' class="my-4">
    <h4 class="card-title">Suggest a custom dose plan</h4>
    <div>
      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <b-form @submit.prevent="runSimulation">
          <b-input-group>
            <label title="Delivery Method">Delivery</label>
            <v-select
                id="method"
                ref="method"
                placeholder="Select a method"
                v-model="form.method"
                class="w-80 text-left"
                :options="methodList"
                :clearable="false"
                :reduce="item => item.code"
            ></v-select>
          </b-input-group>
          <b-input-group>
            <label title="Dose amount">Dose</label>
            <ValidationProvider name="Dose" vid="initialDose" :rules="rules.dose" v-slot="v">
              <b-form-input
                  id="initDose"
                  v-model="form.amount"
                  type="number"
                  :number="true"
                  class="input-with-unit"
                  placeholder="Amount"
                  :state="getValidationState(v)"
                  aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback id="input-1-live-feedback">{{ v.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
            <b-input-group-append>
              <ValidationProvider vid="doseUnit">
                <b-form-select
                    id="initDoseUnit" ref="doseUnit"
                    v-model="form.amountUnit"
                    class="unit-dropdown"
                    :options="units.dose"
                    :clearable="false"
                >
                </b-form-select>
              </ValidationProvider>
            </b-input-group-append>
          </b-input-group>
          <b-input-group v-if="form.method==='IV'">
            <label title="Infusion duration">Duration</label>
            <ValidationProvider name="Duration" vid="duration" :rules="rules.duration" v-slot="v">
              <b-form-input
                  id="duration"
                  v-model="form.duration"
                  type="number"
                  :number="true"
                  class="input-with-unit"
                  placeholder="Infusion duration"
                  :state="getValidationState(v)"
                  aria-describedby="input-2-live-feedback"
              />
              <b-form-invalid-feedback id="input-2-live-feedback">{{ v.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
            <b-input-group-append>
              <ValidationProvider vid="durationUnit">
                <b-form-select
                    id="durationUnit" ref="durationUnit"
                    v-model="form.durationUnit"
                    class="unit-dropdown"
                    :options="units.duration"
                    :clearable="false"
                >
                </b-form-select>
              </ValidationProvider>
            </b-input-group-append>
          </b-input-group>
          <b-input-group v-if="form.method==='IV'">
            <label title="Dosing interval">Interval</label>
            <ValidationProvider name="interval" vid="interval" :rules="rules.interval" v-slot="vs">
              <b-form-input
                  id="interval"
                  v-model="form.interval"
                  type="number"
                  :number="true"
                  class="input-with-unit"
                  placeholder="Dosing interval"
                  :state="getValidationState(vs)"
                  aria-describedby="input-3-live-feedback"
              />
              <b-form-invalid-feedback id="input-3-live-feedback">{{ vs.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
            <b-input-group-append>
              <ValidationProvider vid="intervalUnit">
                <b-form-select
                    id="intervalUnit"
                    v-model="form.intervalUnit"
                    class="unit-dropdown"
                    :options="units.interval"
                    :clearable="false"
                >
                </b-form-select>
              </ValidationProvider>
            </b-input-group-append>
          </b-input-group>
          <div class="form-check form-switch float-end w-75" :v-if="hasAdministeredDoses">
            <input id="switch-loading"
                   class="form-check-input"
                   type="checkbox"
                   v-model="form.loading"/>
            <label for="switch-loading"
                   class="form-check-label">Estimate a loading dose</label>
          </div>
          <b-form-row>
            <div class="float-end mb-5">
              <b-button class="btn-lg" variant="success"
                        title="Run a simulation with custom data"
                        :disabled="invalid || !isFormValid"
                        @click="runSimulation">RUN CUSTOM
              </b-button>
            </div>
          </b-form-row>
        </b-form>
      </ValidationObserver>
    </div>
    <div v-if="options.length > 0">
      <SimulationDoses :data="options" :method="form.method" :show-calculation="false"/>
      <div class="float-end mb-5">
        <b-button variant="outline-secondary"
                  title="Clear data"
                  @click="clear">CLEAR CUSTOM
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {Methods, units} from "@/models/utils/constants";
import {Simulation} from "@/models/Simulation";
import {Drug} from "@/models/Drug";
import {DrugModel} from "@/models/DrugModel";
import {Pathogen} from "@/models/Pathogen";
import {Target} from "@/models/Target";
import {Delivery} from "@/models/Delivery";
import {Patient} from "@/models/Patient";
import SimulationDoses from "@/components/display/SimulationDoses.vue";
import {Custom} from "@/models/Custom";
import {generateContinuousDosingOptions} from "@/models/utils/calculations_continuous";
import {DynamicSimulation} from "@/models/DynamicSimulation";

export default {
  name: "CustomForm",
  components: {SimulationDoses},
  props: {
    sim: {type: DynamicSimulation}
  },
  watch: {
    sim() {
      this.loadSim()
    },
    drug() {
      this.methodList = this.getMethodList();
    }
  },
  mounted() {
    this.loadSim()
  },
  data() {
    return {
      units: units,
      options: [],
      form: this.initForm(),
      methodList: this.getMethodList(),
      rules: {
        dose: {
          doseBetween: ({
            unit: '@doseUnit',
            model: this.sim.model,
          })
        },
        interval: {
          intervalBetween: true,
          intervalDuration: ({
            duration: '@duration',
            durationUnit: '@durationUnit',
            intervalUnit: '@intervalUnit'
          })
        },
        duration: {
          durationBetween: ({
            unit: '@durationUnit'
          })
        },
      },
      customSim: null
    }
  },
  computed: {
    getSimulation() {
      return this.$store.getters.getSimulation
    },
    isFormValid() {
      return !(this.form.amount === null || (this.form.method === "IV" && (this.form.duration === null || this.form.interval === null)));
    },
    drug() {
      return this.$store.getters.getDrug;
    },
    hasAdministeredDoses() {
      return this.sim?.hasAdministeredDoses();
    }
  },
  methods: {
    nformat(value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals);
    },
    initForm() {
      return {
        ...this.form,
        // method: this.sim?.delivery?.method,
        amount: null,
        amountUnit: 'mg',
        duration: null,
        durationUnit: 'hours',
        interval: null,
        intervalUnit: 'hours',
        loading: false
      }
    },
    loadSim() {
      this.options = [];
      // if (this.sim) {
      //   const sim = new Simulation()
      //   sim.simPatient = new Patient({...this.sim.simPatient})
      //   sim.simDrug = new Drug({...this.sim.simDrug})
      //   sim.simModel = this.sim.simModel
      //   sim.simPathology = new Pathogen({...this.sim.simPathology})
      //   sim.simTarget = new Target({...this.sim.simTarget})
      //   sim.simDoses = [...this.sim.simDoses]
      //   sim.simDelivery = new Delivery({...this.sim.simDelivery})
      //   sim.simAdministeredDoses = [...this.sim.simAdministeredDoses]
      //   sim.pkParams = [...this.sim.pkParams]
      //   this.customSim = sim
      // }
    },
    start($event) {
      this.$store.dispatch('saveLoading', true)
      setTimeout(() => this.runSimulation($event), 30);
    },
    clear() {
      this.options = []
      this.form = this.initForm();
      this.$store.dispatch('clearSelectedPlan');
    },
    getValidationState(validator) {
      return !validator.pristine && validator.validated ? validator.valid : null;
    },
    getMethodList() {
      const list = Object.keys(Methods).map((m) => {
        return {code: m, label: Methods[m]}
      })
      // Filter by methods available for drug
      if (this.drug) {
        let drugMethods = this.drug.methods.map((d) => d.type)
        return list.filter((m) => drugMethods.indexOf(m.code) > -1)
      }
      return list
    },
    runSimulation($event) {
      // clear current selected graph
      this.$store.dispatch('clearSelectedPlan')

      this.$nextTick(() => {
        if (this.sim) {
          const custom = new Custom({...this.form})
          const customPlan = this.sim.calculateCustom(custom, true);
          this.$store.dispatch('saveSelectedPlan', customPlan);
          const plans = [customPlan];
          const newPlans = plans.map((s) => {
            if (s.doseRegime.deliveryMethod === 'CONT') {
              return {
                // Interval: s.doseRegime.interval,2
                // intervalUnit: 'h',
                Dose: s.doseRegime.dose,
                doseUnit: 'mg',
                // Rate: s.doseRegime.duration,
                // rateUnit: s.recommendation.durationUnit,
                AUC: this.nformat(s.mainResult.auc, 2),
                aucUnit: "mcg*hr/mL",
                SS: this.nformat(s.mainResult.ss, 2),
                ssUnit: 'mcg/mL',
                plan: s,
                _rowVariant: s._rowVariant
              }
            } else if (this.sim.model.getCompartment() === 2) {
              return {
                Interval: s.doseRegime.interval,
                intervalUnit: 'h',
                Dose: s.doseRegime.dose,
                doseUnit: 'mg',
                Infusion: s.doseRegime.infusionDuration,
                InfusionUnit: 'h',
                TMIC: this.nformat(s.mainResult.tmic, 2),
                Peak: this.nformat(s.mainResult.peak, 2),
                peakUnit: 'mcg/mL',
                plan: s,
                _rowVariant: s._rowVariant
              }
            } else {
              return {
                Interval: s.doseRegime.interval,
                intervalUnit: 'h',
                Dose: s.doseRegime.dose,
                doseUnit: 'mg',
                Infusion: s.doseRegime.infusionDuration,
                InfusionUnit: 'h',
                AUC: this.nformat(s.mainResult.auc, 2),
                aucUnit: "mcg*hr/mL",
                Peak: this.nformat(s.mainResult.peak, 2),
                peakUnit: 'mcg/mL',
                Trough: this.nformat(s.mainResult.trough, 2),
                troughUnit: 'mcg/mL',
                plan: s,
                _rowVariant: s._rowVariant
              }
            }
          });
          this.options = [...this.options.filter(o => o.plan.doseRegime.deliveryMethod == this.form.method), ...newPlans];
          // this.$store.dispatch('saveCustom', this.options)
          // this.$emit('switchTab', $event, 2)
        }
      })
    },
  }
}
</script>

<style scoped>
#custom form {
  display: flex;
  flex-direction: column;
  justify-items: center;
  margin: 0 28%;
}

.input-group {
  justify-content: start !important;
}

@media (max-width: 940px) {
  #custom form {
    margin: 0 5%;
  }
}

</style>