

/*
  Enums used in model
 */


export enum DeliveryMethod {
    IV = "IV",
    CONTINUOUS = "CONT",
    ORAL = "ORAL"
}

export enum TargetType {
    AUC = 'AUC/MIC',
    PEAK = 'Peak',
    TROUGH = 'Trough',
    STEADY_STATE = 'Steady state',
    FT = "fT>MIC"
}

export enum CalculationType {
    POPULATION = 'Population',
    PATIENT = 'Patient'
}

