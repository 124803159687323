<template>
  <div class="overflow-auto">
    <b-table id="sim-table"
             striped
             fixed
             small
             responsive="sm"
             :items="items"
             :fields="fields"
             label-sort-asc=""
             label-sort-desc=""
             label-sort-clear=""
    >

      <template #cell(Dose)="{item}">{{ item.Dose }} <span v-html="displaySIUnits(item.doseUnit)"></span></template>
      <template #cell(Interval)="{item}">{{ item.Interval }} <span v-html="displaySIUnits(item.intervalUnit)"></span></template>
      <template #cell(Infusion)="{item}">{{ item.Infusion }} <span v-html="displaySIUnits(item.InfusionUnit)"></span></template>
      <template #cell(AUC)="{item}">{{ item.AUC }} <span v-html="displaySIUnits(item.aucUnit)"></span></template>
      <template #cell(TMIC)="{item}">{{ item.TMIC }} %</template>
      <template #cell(Peak)="{item}">{{ item.Peak }} <span v-html="displaySIUnits(item.peakUnit)"></span></template>
      <template #cell(Trough)="{item}">{{ item.Trough }} <span v-html="displaySIUnits(item.troughUnit)"></span></template>
      <template #cell(SS)="{item}">{{ item.SS }} <span v-html="displaySIUnits(item.ssUnit)"></span></template>
      <template #cell(Rate)="{item}">{{ item.Rate }} <span v-html="displaySIUnits(item.rateUnit)"></span></template>
      <template #cell(Calculation)="{item}">{{ item.Calculation }}</template>
      <template #cell(Action)="{item}">
        <b-button title="Select Plan" :variant="buttonClass(item)"
                  @click="selectItem(item)">select<span v-if="buttonClass(item) === 'success'"
                                                        class="text-white">ed</span></b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import {displaySIUnits} from "@/models/utils/constants";

export default {
  name: "SimulationDoses",
  props: {
    data: {
      type: Array
    },
    method: {
      type: String,
      default: 'IV'
    },
    showCalculation: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // simDoses: [],
      fields: this.getFields()
    }
  },
  computed: {
    interval() {
      return this.tab
    },
    items() {
      return this.data
    },
    selectedPlan() {
      return this.$store.getters.getSelectedPlan;
    }
  },
  watch: {
    method() {
      // update fields if method changes
      this.fields = this.getFields()
    },
    showCalculation() {
      // update fields if show calculation changes
      this.fields = this.getFields()
    },
  },
  methods: {
    displaySIUnits,
    selectItem(item) {
      this.$store.dispatch('saveSelectedPlan', item.plan)
    },
    buttonClass(item) {
      return item.plan.id === this.selectedPlan?.id ? 'success' : 'outline-primary';
    },
    getFields() {
      const model = this.$store.getters.getModel
      const fields = this.method === 'CONT' ? [
        {
          key: 'Dose',
          label: 'Dose / 24 Hours',
          sortable: true
        },
        // {
        //   key: 'Rate',
        //   sortable: true
        // },
        {
          key: 'SS',
          label: 'Steady state',
          sortable: true
        },
        {
          key: 'AUC',
          label: 'AUC24',
          sortable: true
        },
        {
          key: 'Action',
          sortable: false
        }
      ] : model.getCompartment() === 2 ? [
        {
          key: 'Interval',
          sortable: true
        },
        {
          key: 'Infusion',
          sortable: true
        },
        {
          key: 'Dose',
          sortable: true
        },
        {
          key: 'TMIC',
          label: 'fT>MIC',
          sortable: true
        },
        {
          key: 'Peak',
          sortable: true
        },
        {
          key: 'Action',
          sortable: false
        }
      ] : [
          {
            key: 'Interval',
            sortable: true
          },
        {
          key: 'Infusion',
          sortable: true
        },
              {
                key: 'Dose',
                sortable: true
              },
              {
                key: 'AUC',
                label: 'AUC24',
                sortable: true
              },
              {
                key: 'Peak',
                sortable: true
              },
              {
                key: 'Trough',
                sortable: true
              },
              {
                key: 'Action',
                sortable: false
              }
          ]
      if (this.showCalculation) {
        fields.push({key: 'Calculation', sortable: false})
      }
      return fields
    }
  }
}
</script>

<style scoped>

</style>