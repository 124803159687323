
export class PkParam {
    key: string | null | undefined;
    label: string | null | undefined;
    value: string | number | null | undefined;
    sd?: string | number | null | undefined;
    unit: string | null | undefined;
    type: string | undefined

    constructor(param: {
        key: string | null | undefined,
        label: string | null | undefined,
        value: string | number | null | undefined,
        sd?: string | number | null | undefined,
        unit: string | null | undefined,
        type: string | undefined
    }) {
        this.key = param.key;
        this.label = param.label;
        this.value = param.value;
        this.sd = param.sd;
        this.unit = param.unit;
        this.type = param.type;
    }

    getValue(): number | null{
        if (this.value) {
            if (typeof this.value === 'number') {
                return this.value;
            }
            return parseFloat(<string>this.value);
        }
        return null;
    }

    getValueOr(defaultValue: number): number{
        if (this.value) {
            if (typeof this.value === 'number') {
                return this.value;
            }
            return parseFloat(<string>this.value);
        }
        return defaultValue;
    }

    getSd(): number | null{
        if (this.sd) {
            if (typeof this.sd === 'number') {
                return this.sd;
            }
            return parseFloat(<string>this.sd);
        }
        return null;
    }

    getSdOr(defaultValue: number): number{
        if (this.sd) {
            if (typeof this.sd === 'number') {
                return this.sd;
            }
            return parseFloat(<string>this.sd);
        }
        return defaultValue;
    }
}


export class PkParamModel {
    private readonly pkParams: PkParam[];
    private readonly pkParamLookup: Record<string, PkParam>;

    constructor(pkParamModel: PkParamModel | null | undefined = null) {
        this.pkParamLookup = {};
        if (pkParamModel) {
            this.pkParams = [...pkParamModel.pkParams];
            for (const param of this.pkParams) {
                if (param.key){
                    this.pkParamLookup[param.key] = param;
                }
            }
        }
        else {
            this.pkParams = [];
        }
    }

    addParam(param: PkParam){
        this.pkParams.push(param);
        if (param.key){
            this.pkParamLookup[param.key] = param;
        }
    }

    addAllParams(params: PkParam[]){
        for (const param of params) {
            this.pkParams.push(param);
            if (param.key){
                this.pkParamLookup[param.key] = param;
            }
        }
    }


    getPk(key: string): PkParam | null {
        return this.pkParamLookup[key];
    }

    getPkValue(key: string): number | null {
        const val = this.getPk(key);
        if (val) {
            return val.getValue();
        }
        return null;
    }

    getPkValueOr(key: string, defaultValue: number): number {
        const val = this.getPk(key);
        if (val){
            return val.getValueOr(defaultValue);
        }
        return defaultValue;
    }

}