import {Target} from "./Target";
import {Pathogen} from "./Pathogen";
import {DrugModel} from "./DrugModel";
import {Method} from "./Method";

export class Drug {
    id: string;
    name: string;
    class: string;
    dosingUnit: number | null | undefined;
    maxDosePerHr?: number | undefined | null;
    target: Target[];
    pathology: Pathogen[] | undefined;
    models: DrugModel[];
    methods: Method[];

    constructor(param: {
        id: string, name: string, class: string, dosingUnit?: number,  maxDosePerHr?: number, target: Target[], pathology?: Pathogen[],
        models: DrugModel[], methods: Method[]
    }) {
        this.id = param.id;
        this.name = param.name;
        this.class = param.class;
        this.dosingUnit = param.dosingUnit;
        this.maxDosePerHr = param.maxDosePerHr;
        this.target = param.target;
        this.pathology = param.pathology;
        this.models = param.models;
        this.methods = param.methods;
    }

    getMethodForType(type: string): Method {
        return this.methods.filter((m: Method) => m.type === type)[0];
    }

    getAmountRange(type:string):number[] | null {
        return this.methods.filter((m: Method) => m.type === type)[0]?.amountRange
    }
    getAmountUnit(type:string):string | null {
        return this.methods.filter((m: Method) => m.type === type)[0]?.amountUnit
    }

    getIntervalRange(type:string):number[] | null {
        return this.methods.filter((m: Method) => m.type === type)[0]?.intervalRange
    }

    getIntervalUnit(type:string):string | null {
        return this.methods.filter((m: Method) => m.type === type)[0]?.intervalUnit
    }

    getInfusionRange(type:string):number[] | null | undefined {
        return this.methods.filter((m: Method) => m.type === type)[0]?.infusionRange;
    }

    getInfusionUnit(type:string):string | null | undefined {
        return this.methods.filter((m: Method) => m.type === type)[0]?.infusionUnit
    }

    getBioavailability():number | undefined | null {
        return this.methods.filter((m: Method) => m.type === 'ORAL')[0]?.bioavailability
    }

    getHalflife():number | undefined | null {
        return this.methods.filter((m: Method) => m.type === 'ORAL')[0]?.halflife
    }

}