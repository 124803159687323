import Vue from 'vue'
import Vuex from 'vuex'
import {drug_models} from "@/models/modelData/DrugModels.ts";
import {calculateSince, deduplicateDoses} from "@/components/services/common";
import {DoseTypes} from "@/models/utils/constants";
import {DynamicSimulation} from "@/models/DynamicSimulation";
import {Patient} from "@/models/Patient";
import {DrugModel} from "@/models/DrugModel";
import {Pathogen} from "@/models/Pathogen";
import {Dose} from "@/models/Dose";
import {Target} from "@/models/Target";
import {Drug} from "@/models/Drug";
import {Delivery} from "@/models/Delivery";

Vue.use(Vuex)
const defaultState = {
    drugId: null,
    modelId: null,
    patient: null,
    delivery: null,
    target: null,
    pathology: null,
    doses: [],
    simulation: new DynamicSimulation( {
        patient: null,
        pathology: null,
        doses: [],
        target: null,
        delivery: null}),
    error: null,
    selectedId: null,
    selectedPlan: null,
    loading: false,
    auth: undefined
}
export default new Vuex.Store({
    state: {...defaultState},
    mutations: {
        setDrug(state, drug) {
            state.drugId = drug?.code;
            state.simulation = new DynamicSimulation( {
                patient: state.patient,
                modelId: state.modelId,
                pathology: state.pathology,
                doses: state.doses,
                target: state.target,
                drug: drug,
                delivery: state.delivery
            });
        },
        setDrugId(state, drugId) {
            state.drugId = drugId;
            state.simulation = new DynamicSimulation( {
                patient: state.patient,
                modelId: state.modelId,
                pathology: state.pathology,
                doses: state.doses,
                target: state.target,
                drugId: drugId,
                delivery: state.delivery
            });
            state.selectedPlan = null;
        },
        setSelectedModel(state, model) {
            state.modelId = model;
            state.simulation = new DynamicSimulation( {
                patient: state.patient,
                modelId: state.modelId,
                pathology: state.pathology,
                doses: state.doses,
                target: state.target,
                drugId: state.drugId,
                delivery: state.delivery
            });
            state.selectedPlan = null;
        },
        setSelectedId(state, id) {
            state.selectedId = id;
        },
        setSelectedPlan(state, plan) {
            state.selectedPlan = plan;
        },
        clearSelectedPlan(state) {
            state.selectedPlan = null;
        },
        setPatient(state, patient) {
            state.patient = new Patient({...patient});
            state.simulation = new DynamicSimulation( {
                patient: state.patient,
                modelId: state.modelId,
                pathology: state.pathology,
                doses: state.doses,
                target: state.target,
                drugId: state.drugId,
                delivery: state.delivery
            });
            state.selectedPlan = null;
        },
        setDelivery(state, item) {
            state.delivery = {...item};
            state.simulation = new DynamicSimulation( {
                patient: state.patient,
                modelId: state.modelId,
                pathology: state.pathology,
                doses: state.doses,
                target: state.target,
                drugId: state.drugId,
                delivery: state.delivery
            });
            state.selectedPlan = null;
        },
        setTarget(state, item) {
            state.target = {...item};
            state.simulation = new DynamicSimulation( {
                patient: state.patient,
                modelId: state.modelId,
                pathology: state.pathology,
                doses: state.doses,
                target: state.target,
                drugId: state.drugId,
                delivery: state.delivery
            });
            state.selectedPlan = null;
        },
        setPathology(state, item) {
            state.pathology = {...item};
            state.simulation = new DynamicSimulation( {
                patient: state.patient,
                modelId: state.modelId,
                pathology: state.pathology,
                doses: state.doses,
                target: state.target,
                drugId: state.drugId,
                delivery: state.delivery
            });
            state.selectedPlan = null;
        },
        setDoses(state, items) {
            state.doses = [...items];
            // calculate interval since dose
            if (state.doses.length > 1) {
                state.doses = calculateSince([...state.doses])
            }
            state.simulation = new DynamicSimulation( {
                patient: state.patient,
                modelId: state.modelId,
                pathology: state.pathology,
                doses: state.doses,
                target: state.target,
                drugId: state.drugId,
                delivery: state.delivery
            });
            state.selectedPlan = null;
        },
        clearDoses(state) {
            state.doses = []
            state.simulation = new DynamicSimulation( {
                patient: state.patient,
                modelId: state.modelId,
                pathology: state.pathology,
                doses: state.doses,
                target: state.target,
                drugId: state.drugId,
                delivery: state.delivery
            });
            state.selectedPlan = null;
        },
        addDose(state, item) {
            const n1 = state.doses.length;
            const n2 = item.length ?? 1;
            if (item.length !== undefined) {
                const tmp = [...state.doses, ...item];
                state.doses = deduplicateDoses(tmp);
            } else {
                const tmp = {...item};
                state.doses = deduplicateDoses([...state.doses, tmp]);
            }
            // pick up deduplicate
            if (state.doses.length !== n1 + n2) {
                state.error = 'This is a duplicate by type and datetime. Please try again.';
            }
            // calculate interval since dose
            if (state.doses.length > 1) {
                state.doses = calculateSince([...state.doses]);
            }
            state.simulation = new DynamicSimulation( {
                patient: state.patient,
                modelId: state.modelId,
                pathology: state.pathology,
                doses: state.doses,
                target: state.target,
                drugId: state.drugId,
                delivery: state.delivery
            });
            state.selectedPlan = null;
        },
        removeDose(state, item) {
            state.doses = [...state.doses.filter((d) => d.id !== item.id)]
            if (state.doses.length > 1) {
                state.doses = calculateSince([...state.doses])
            }
            state.simulation = new DynamicSimulation( {
                patient: state.patient,
                modelId: state.modelId,
                pathology: state.pathology,
                doses: state.doses,
                target: state.target,
                drugId: state.drugId,
                delivery: state.delivery
            });
            state.selectedPlan = null;
        },
        setSimulation(state, item) {
            state.simulation = {...item}
            state.selectedPlan = null;
        },
        clearSimulation(state) {
            state.drugId = null;
            state.modelId = null;
            state.patient = null;
            state.delivery = null;
            state.target = null;
            state.pathology = null;
            state.doses = [];
            state.patient = null;
            state.pathology = null;
            state.doses = [];
            state.target = null;
            state.delivery = null;
            state.error = null;
            state.selectedId = null;
            state.simulation = new DynamicSimulation( {
                patient: state.patient,
                modelId: state.modelId,
                pathology: state.pathology,
                doses: state.doses,
                target: state.target,
                drugId: state.drugId,
                delivery: state.delivery
            });
            state.selectedPlan = null;
        },
        clearDrug(state){
            state.drugId = null
            state.modelId = null
            state.simulation = new DynamicSimulation( {
                patient: state.patient,
                modelId: state.modelId,
                pathology: state.pathology,
                doses: state.doses,
                target: state.target,
                drugId: state.drugId,
                delivery: state.delivery
            });
            state.selectedPlan = null;
        },
        clearModel(state) {
            state.modelId = null
            state.simulation = new DynamicSimulation( {
                patient: state.patient,
                modelId: state.modelId,
                pathology: state.pathology,
                doses: state.doses,
                target: state.target,
                drugId: state.drugId,
                delivery: state.delivery
            });
            state.selectedPlan = null;
        },
        clearError(state) {
            state.error = null
        },
        clearAll(state) {
            Object.assign(state, defaultState)
        },
        setLoading(state, value) {
            state.loading = value
        },
        setAuth(state, member) {
            state.auth = member
        }
    },
    actions: {
        saveDrug({commit}, drug) {
            commit('setDrug', drug)
        },
        saveModel({commit}, modelId) {
            commit('setSelectedModel', modelId)
        },
        savePatient({commit}, patient) {
            commit('setPatient', patient)
        },
        saveDelivery({commit}, item) {
            commit('setDelivery', item)
        },
        saveTarget({commit}, item) {
            commit('setTarget', item)
        },
        savePathology({commit}, item) {
            commit('setPathology', item)
        },
        saveDoses({commit}, item) {
            commit('setDoses', item)
        },
        addDose({commit}, item) {
            commit('addDose', item)
        },
        removeDose({commit}, item) {
            commit('removeDose', item)
        },
        clearDoses({commit}) {
            commit('clearDoses')
        },
        saveSimulation({commit}, item) {
            commit('setSimulation', item)
            commit('setLoading', false)
        },
        clearSimulation({commit}) {
            commit('clearSimulation')
            commit('clearError')
            commit('setLoading', false)
        },
        clearModel({commit}) {
            commit('clearModel')
        },
        loadPatientData({commit}, patient) {
            console.log("Load patient from ", patient);
            if (patient.id) {
                commit('setSelectedId', patient.id)
            }
            // dervie drugId from modelId
            let drugId = null
            if (patient.modelId) {
                drug_models.forEach((d) => {
                    const found = d.models.find((i) => i.code === patient.modelId)
                    if (found) {
                        drugId = d.id
                    }
                })
            }
            commit('setDrugId', drugId)
            commit('setSelectedModel', patient.modelId)
            const drug = this.getters.getDrug
            commit('setDrug', drug)
            commit('setPatient', patient.patient)
            commit('setDelivery', patient.delivery ?? {daysToDisplay: 3, applyInterindividualVariability: true})
            commit('setTarget', patient.target)
            commit('setPathology', patient.pathology)
            commit('setDoses', patient.doses)
            console.log('loades simulation ', this.state.simulation);
        },
        clearError({commit}) {
            commit('clearError')
        },
        clearAll({commit}) {
            commit('clearAll')
        },
        saveSelectedId({commit}, id) {
            commit('setSelectedId', id)
        },
        saveSelectedPlan({commit}, item) {
            commit('setSelectedPlan', item);
        },
        clearSelectedPlan({commit}) {
            commit('clearSelectedPlan');
        },
        saveLoading({commit}, value) {
            commit('setLoading', value)
        },
        login({commit}, member) {
            commit('setAuth', member)
        },
        logout({commit}) {
            commit('setAuth', undefined)
        },
    },
    getters: {
        getDrug: (state) => {
            if (state.drug) {
                return state.drug
            } else if (state.modelId) {
                return drug_models.filter((d) => {
                    return d.models.find((m) => m.code === state.modelId)
                })[0]
            }
            return null
        },
        getModel: (state) => {
            let model = null
            if (state.modelId) {
                drug_models.forEach((d) => {
                    const found = d.models.find((m) => m.code === state.modelId)
                    if (found) {
                        model = found
                    }
                })
            }
            return model
        },
        getPatient: (state) => {
            return state.patient;
        },
        getDelivery: (state) => {
            return state.delivery;
        },
        getTarget: (state) => {
            return state.target;
        },
        getPathology: (state) => {
            return state.pathology;
        },
        getDoses: (state) => {
            return state.doses;
        },
        getSimulation: (state) => {
            return state.simulation
        },
        getError: (state) => {
            return state.error
        },
        getSelectedId: (state) => {
            return state.selectedId
        },
        getLoading: (state) => {
            return state.loading
        },
        getMember: (state) => {
            return state.auth
        },
        getSelectedPlan: (state) => {
            return state.selectedPlan;
        },
        isReady: (state) => {
            // Must have creatinine, model and patient loaded for running simulation
            // Not required to provide a dose for a simulation
            if (state.simulation){
                return state.simulation.isReady();
            }
            return false;
            // const has_creatinine = state.doses.filter((d) => {
            //     return (d.type === DoseTypes.SECR || d.type === DoseTypes.CLCR)
            // }).length > 0
            // const has_alerts = state.doses.filter((d) => d.sinceAlert).length > 0
            // return state.patient && state.modelId && has_creatinine && !has_alerts
        }
    }
})
