<template>
  <b-form-group class="accordion" role="tablist">
    <b-card no-body :class="borderClass">
      <b-card-header header-tag="header" role="tab">
        <div v-b-toggle.target-panel>TARGET</div>
      </b-card-header>
      <b-collapse id="target-panel" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <b-form @submit.prevent="saveForm">
              <b-input-group class="justify-between">
                <label>Method</label>
                <ValidationProvider vid="pdtMethod">
                  <b-form-select
                      id="target"
                      ref="pdtMethod"
                      placeholder="Select method"
                      class="unit-dropdown"
                      v-model="form.target"
                      @input="updateTarget"
                      value-field="value"
                      html-field="text"
                      :options="targetList"
                      :clearable="false"
                  ></b-form-select>
                </ValidationProvider>
                <ValidationProvider vid="pdtUnit">
                  <b-form-select
                      id="pdtUnit"
                      ref="pdtUnit"
                      v-model="form.pdtUnit"
                      class="unit-dropdown"
                      @input="saveForm"
                      value-field="value"
                      html-field="text"
                      :options="targetUnits"
                      :clearable="false"
                      :disabled="true"
                      required>
                  </b-form-select>
                </ValidationProvider>
              </b-input-group>
              <b-input-group>
                <label>Range</label>
                <ValidationProvider name="pdtMin" :rules="rules" vid="pdtMin" v-slot="v">
                  <b-form-input
                      id="pdt"
                      ref="pdtMin"
                      v-model="form.pdtMin"
                      type="number"
                      :number="true"
                      class="input-with-unit"
                      placeholder="Min value"
                      @input="saveForm"
                      :state="getValidationState(v)"
                      aria-describedby="input-1-live-feedback"
                      required/>
                  <b-form-invalid-feedback id="input-1-live-feedback">{{ v.errors[0] }}</b-form-invalid-feedback>
                </ValidationProvider>
                <span class="px-1">to</span>
                <ValidationProvider name="pdtMax" :rules="rules" vid="pdtMax" v-slot="v">
                  <b-form-input
                      id="pdt"
                      ref="pdtMax"
                      v-model="form.pdtMax"
                      type="number"
                      :number="true"
                      class="input-with-unit"
                      placeholder="Max value"
                      @input="saveForm"
                      :state="getValidationState(v)"
                      aria-describedby="input-2-live-feedback"
                      required/>
                  <b-form-invalid-feedback id="input-2-live-feedback">{{ v.errors[0] }}</b-form-invalid-feedback>
                </ValidationProvider>
              </b-input-group>
              <hr/>
              <span class="flex align-content-center">
              <b-button class="rounded-circle shadow bg-secondary" title="Add target"
                        @click="saveForm" :disabled="invalid || !isFormValid"><PlusIcon></PlusIcon></b-button>
            </span>
            </b-form>
          </ValidationObserver>
        </b-card-body>
      </b-collapse>
    </b-card>
  </b-form-group>
</template>

<script>
import {displaySIUnits, targets, units} from "@/models/utils/constants.js";
import PlusIcon from 'vue-material-design-icons/Plus.vue'
import {DrugTargetNames} from "../../models/utils/constants";

export default {
  name: "TargetInput",
  props: ["targetData", "drug"],
  components: {PlusIcon},
  data() {
    return {
      form: this.initForm(),
      targetList: this.getTargetList(),
      targetUnits: units.targetConcentration.map((u) => {
        return {value: u, text: displaySIUnits(u)}
      }),
      error: null,
      rules: {
        required: true,
        targetBetween: ({
          method: '@pdtMethod',
          unit: '@pdtUnit',
          minValue: '@pdtMin',
          maxValue: '@pdtMax',
          drug: this.drug,
        })
      }
    }
  },
  watch: {
    targetData() {
      this.form = this.initForm()
    },
    drug() {
      this.targetList = this.getTargetList();
    }
  },
  computed: {
    borderClass() {
      const currentTarget = this.$store.getters.getTarget
      if (currentTarget == null || currentTarget.target == null || currentTarget.pdtMin == null || currentTarget.pdtMax == null || currentTarget.pdtUnit == null){
        console.log("currentTarget", currentTarget);
        return 'mb-1 border-danger'
      }
      return 'mb-1'
    }
  },
  methods: {
    initForm() {
      const target = this.targetData ?? null
      return {
        ...this.form,
        target: target ? target.target : this.drug ? this.drug.target[0].type : null,
        pdtMin: target ? target.pdtMin : this.drug ? this.drug.target[0].min : null,
        pdtMax: target ? target.pdtMax : this.drug ? this.drug.target[0].max : null,
        pdtUnit: target ? target.pdtUnit : this.drug ? this.drug.target[0].unit : null
      }
    },
    getTargetList() {
      return this.drug ? this.drug.target.map((t) => {
            return {value: t.type, text: DrugTargetNames[t.type]}}): targets;
    },
    updateTarget() {
      if (this.drug && this.form.target) {
        const target = this.drug.target.filter((t) => t.type === this.form.target)[0]
        if (target) {
          this.form.pdtMin = target.min
          this.form.pdtMax = target.max
          this.form.pdtUnit = target.unit
          this.saveForm();
        }
      }
    },
    saveForm() {
      this.$refs.observer.validate().then(success => {
        if (success) {
          this.$store.dispatch('saveTarget', this.form)
        }
      })
    },
    getValidationState(validator) {
      return !validator.pristine && validator.validated ? validator.valid : null;
    },
    isFormValid() {
      return this.form.pdtMin !== null && this.form.pdtMax !== null;
    }
  }
}
</script>

<style scoped>
span > .unit-dropdown {
  margin: 0;
}
</style>
