<template>
  <b-form-group class="accordion" role="tablist">
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" role="tab">
        <div v-b-toggle.delivery-panel>SIMULATION DELIVERY</div>
      </b-card-header>
      <b-collapse id="delivery-panel" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <b-form @submit.prevent="saveForm">
              <div v-if="hasAdministeredDoses">
                <input id="custom-start"
                       class="form-check-input"
                       type="checkbox"
                       @input="customStartChange"
                       v-model="form.customStart"/>
                <label for="custom-start"
                       class="form-check-label">Custom Simulation Start</label>
                <div v-if="form.customStart">
                    <p class="text-lg text-black font-semibold">Enter date and time for simulation start</p>
                    <DateTimeInput :field="fieldName" @datetimeEvent="simulationDateTimeValue"/>
                </div>
                <div v-if="customStartError" style="color: #dc3545;">{{ customStartError }}</div>
              </div>
              <hr/>
<!--              <p>Select delivery method for simulation</p>-->
<!--              <v-select-->
<!--                  id="method"-->
<!--                  ref="method"-->
<!--                  placeholder="Select a method"-->
<!--                  v-model="form.method"-->
<!--                  class="w-80 text-left"-->
<!--                  @input="saveForm"-->
<!--                  :options="methodList"-->
<!--                  :clearable="false"-->
<!--                  :reduce="item => item.code"-->
<!--              ></v-select>-->
<!--              <br/>-->
<!--              <b-input-group v-if="form.method==='IV'">-->
<!--                <label>Duration</label>-->
<!--                <ValidationProvider name="duration" :rules="rulesDuration" vid="duration" v-slot="v">-->
<!--                  <b-form-input-->
<!--                      id="duration"-->
<!--                      v-model="form.duration"-->
<!--                      type="number" step="0.01"-->
<!--                      :number="true"-->
<!--                      class="input-with-unit"-->
<!--                      placeholder="Suggested duration"-->
<!--                      @input="saveForm"-->
<!--                      :state="getValidationState(v)"-->
<!--                      aria-describedby="input-2-live-feedback"-->
<!--                  />-->
<!--                  <b-form-invalid-feedback id="input-2-live-feedback">{{ v.errors[0] }}</b-form-invalid-feedback>-->
<!--                </ValidationProvider>-->
<!--                <b-input-group-append>-->
<!--                  <ValidationProvider vid="durationUnit">-->
<!--                    <b-form-select-->
<!--                        id="durationUnit"-->
<!--                        ref="durationUnit"-->
<!--                        v-model="form.durationUnit"-->
<!--                        class="unit-dropdown"-->
<!--                        @input="saveForm"-->
<!--                        :options="units.duration"-->
<!--                        :clearable="false"-->
<!--                    >-->
<!--                    </b-form-select>-->
<!--                  </ValidationProvider>-->
<!--                </b-input-group-append>-->
<!--              </b-input-group>-->
<!--              <hr/>-->
              <b-input-group>
                <label>Days to display</label>
                <ValidationProvider name="DaysToDisplay" :rules="rulesDaysToDisplay" vid="DaysToDisplay" v-slot="v">
                  <b-form-input
                      id="DaysToDisplay"
                      v-model="form.daysToDisplay"
                      type="number" step="1"
                      :number="true"
                      placeholder="Days to display"
                      @input="saveForm"
                      :state="getValidationState(v)"
                      aria-describedby="input-3-live-feedback"
                  />
                  <b-form-invalid-feedback id="input-3-live-feedback">{{ v.errors[0] }}</b-form-invalid-feedback>
                </ValidationProvider>
              </b-input-group>
              <div>
                <input id="switch-variability"
                       class="form-check-input"
                       type="checkbox"
                       v-model="form.applyInterindividualVariability"/>
                <label for="switch-variability"
                       class="form-check-label">Include interindividual variability</label>
              </div>

              <span class="flex align-content-center">
              <b-button class="rounded-circle shadow bg-secondary" title="Add target"
                        @click="saveForm" :disabled="invalid || !isFormValid"><PlusIcon></PlusIcon></b-button>
            </span>
            </b-form>
          </ValidationObserver>
        </b-card-body>
      </b-collapse>
    </b-card>
  </b-form-group>
</template>

<script>
import {Methods, units} from "@/models/utils/constants.js";
import PlusIcon from 'vue-material-design-icons/Plus.vue'
import DateTimeInput from "@/components/inputs/DateTimeInput.vue";
import {extend} from "vee-validate";

export default {
  name: "DeliveryInput",
  components: {DateTimeInput, PlusIcon},
  data() {
    return {
      fieldName: "simulation_start",
      form: this.initForm(),
      units: units,
      error: null,
      methodList: this.getMethodList(),
      rulesDuration: {
        durationBetween: ({
          unit: '@durationUnit',
        })
      },
      customStartError: null,
      rulesDaysToDisplay: {
        between: ({min: 2, max: 7})
      },
    }
  },
  props: ["deliveryData"],
  watch: {
    deliveryData() {
      this.form = this.initForm()
    }
  },
  computed: {
    getSimulation(){
      return this.$store.getters.getSimulation;
    },
    hasAdministeredDoses(){
      const simulation = this.$store.getters.getSimulation;
      return simulation && simulation.hasAdministeredDoses();
    },
    isFormValid() {
      return this.customStartError == null;
    },
  },
  methods: {
    initForm() {
      const delivery = this.deliveryData ?? null
      return {
        ...this.form,
        method: delivery ? delivery.method : 'IV',
        duration: delivery ? delivery.duration : 1,
        durationUnit: delivery ? delivery.durationUnit : 'hours',
        daysToDisplay: delivery ? delivery.daysToDisplay : 3,
        customStart: delivery && delivery.startDT,
        startDT: delivery ? delivery.startDT : null,
        applyInterindividualVariability: delivery?.applyInterindividualVariability ?? true,
      }
    },
    saveForm() {
      this.$refs.observer.validate().then(success => {
        if (success) {
          this.$store.dispatch('saveDelivery', this.form)
        }
      });
    },
    getValidationState(validator) {
      return !validator.pristine && validator.validated ? validator.valid : null;
    },
    simulationDateTimeValue(event) {
      if (event.field === this.fieldName) {
        const sim = this.$store.getters.getSimulation
        const minStart = (sim != null) ? sim.minimumSimulationStart() : null
        // console.log('Testing simulation data', event.value, minStart, this.form.startDT);
        if (minStart != null && event.value != null) {
          if (minStart.getTime() > event.value.getTime()){
            this.customStartError = `Simulation can only start after last administered dose ${minStart}`
            return;
          }
        }
        this.customStartError = null;
        this.form.startDT = event.value;
      }
    },
    customStartChange(event){
      if (!event.value){
        this.form.startDT = null;
        this.customStartError = null;
      }
    },
    getMethodList() {
      const list = Object.keys(Methods).map((m) => {
        return {code: m, label: Methods[m]}
      })
      // Filter by methods available for drug
      const drug = this.$store.getters.getDrug
      if (drug) {
        let drugMethods = drug.methods.map((d) => d.type)
        return list.filter((m) => drugMethods.indexOf(m.code) > -1)
      }
      return list
    }
  }
}
</script>

<style scoped>

</style>
