export const genders = ["Female", "Male"]

export const targets = ["AUC", "AUC/MIC", "fT>MIC"]
export const units = {
    "age": ["days", "years"],
    "height": ["cm", "in"],
    "weight": ["kg", "lb"],
    "duration": ["hours", "minutes"],
    "interval": ["hours"],
    "creatinineConcentration": ["umol/L", "mg/dL"],
    "crcl": ["mL/min", "L/hr"],
    "micConcentration": ["mg/L", "mcg/mL"],
    "targetConcentration": ["mcg*hr/mL", 'mg/L'],
    "concentration": ["mg/L", "mcg/mL"],
    "dose": ["mg", "g"],
    "timezone": ["Brisbane"],
    "rate": ["mg/min", "mg/h"]
}

export const DoseTypes = {
    SECR: 'SeCR',
    CLCR: 'CLCR',
    DOSE: 'DOSE',
    CONC: 'CONC',
}

export const ClearanceMethods = {
    SeCR: 'Serum Creatinine',
    CLCR: 'CLCR (urinary)'
}

export const PkType = {
    PATIENT: 'Patient',
    EMPIRIC: 'Empiric',
    OPTIMISED: 'Optimised'
}

export const Alerts = {
    SINCE_INFUSION: 'Too close to infusion time',
    SINCE_MAX_WEEK: 'More than 1 week from other doses',
    SINCE_BEFORE: 'Before dose given'
}

export const siUnits = {
    "years": "y",
    "hours": "h",
    "hrs": "h",
    "/hr": "/h",
    "L/hr": "L/h",
    "days": "d",
    "umol/L": "&micro;mol/L",
    "mcg/mL": "&micro;g/mL",
    "mcg*hr/mL": "&micro;g*h/mL",
    "m2": "m&sup2;"
}

export const displaySIUnits = (unit) => {
    return siUnits[unit] ?? unit
}

export const Methods = {
    IV: 'Short-term infusion',
    CONT: 'Continuous infusion',
    ORAL: 'Oral administration'
}

export const DrugTargetNames = {
    'AUC/MIC': 'AUC/MIC',
    'Peak': 'Peak',
    'Trough': 'Trough',
    'Steady state': 'CI [Steady State]',
    'fT>MIC': 'fT>MIC',
}

// Search a map by value
// @param map - list of key:value pairs
// @param searchValue
// @return [key,value] or undefined
export const getByValue = (map, searchValue) => {
    return Object.entries(map).filter(([key, value], idx) => {
        if (value === searchValue) {
            return key
        }
    })[0]
}


